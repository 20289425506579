import React, { Component } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Redirect, Switch, Route, useLocation } from "react-router-dom";

import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Drawer } from "@material-ui/core";
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoopIcon from '@material-ui/icons/Loop';
import { Animated } from "react-animated-css";
import { mdiBank } from '@mdi/js';
import Icon from '@mdi/react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BranchBankForm from "./BranchBankForm";
import { translations } from '../../../translate.js';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class branchBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFiliter: false,
            openleftModal: false,
            data: [],
            loading: 0,
            limit: 10,
            activePage: 1,
            dataCount: 100,
            typeOfAction: '',
            bankId: this.props.match.params.bankId,
            bankTitle: this.props.match.params.bankTitle,
            class: [],
            countryList: [],
            centerList: [],
            currencyList:[],
            currency:''
        }
        this.reloadData = this.reloadData.bind(this)

    }


    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            if (name == 'country') {
                this.getcity(value);

            }
            this.getAllbranchBank()
        })
    }

    componentDidMount() {
        this.getAllbranchBank();
        this.getAllCitiesAndCountries()
        this.getAllCurrancy()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }

  // https://hojabook.com/API/currency.php?type=getAllCurrency&userId=1&token=0d669d29414e458c6be308121f40d227&companyId=1&page=all&limit=2
  getAllCurrancy() {
    fetch(window.API_URL_CURRENCY, {
        method: "POST",
        body: JSON.stringify({
            type: "getAllCurrency",
            'userId': localStorage.getItem('USER_ID'),
            'token': localStorage.getItem('USER_TOKEN'),
            'companyId': localStorage.getItem('USER_COMPANYID'),
            'page': 'all',
            'limit': 2
        }),
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(function (response) {
            return response.json();
        })
        .then((data) => {
            if (data['code'] == 200) {
                this.setState({
                    currencyList: data['data'],

                }, () => {

                });

            }
        });
}
    // https://hojabook.com/API/bankBranches.php?type=getBankBranchesByBankIdAndCompanyId&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&page=1&limit=10&bankId=1   

    getAllbranchBank() {

        console.log( 'countryId', this.state.country,
        'cityId',this.state.city,
        'currency', this.state.currency);
        this.setState({ loading: 0 })
        fetch(window.API_URL_BRANCH, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBankBranchesByBankIdAndCompanyId',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'page': this.state.activePage,
                'limit': this.state.limit,
                'bankId': this.props.match.params.bankId,
                'countryId': this.state.country,
                'cityId': this.state.city,
                'currency': this.state.currency


            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {

            return response.json();
        }).then(data => {
            console.log(data);
            if (data['code'] == 200) {
                this.setState({
                    data: data['data'],
                    dataCount: data['count'],
                    loading: 1
                });
            } else {

            }

        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getAllbranchBank()

        });
    }


    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1, loading: 0 }, function () {
            this.getAllbranchBank()
        });
    }




    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }


    toggleDrawer = (open, type, id, data) => (event) => {
        this.setState({ openleftModal: open, typeOfAction: type, errorFlag: false, id: id, selectedData: data }, function () {
        });

    };

    CloseDrawerFunc = () => {
        this.setState({
            openleftModal: false,
            typeOfAction: ''
        });
    };

    refresh = () => {
        this.setState({
            openleftModal: false,
            data: [],
            loading: 0,
            limit: 10,
            activePage: 1,
            dataCount: 100,
            searchKey: '',
            country:'',
            city:'',
            currency:'',
            centerList:[],

        }, () => {
            this.getAllbranchBank()
        })
    }


    deleteBankBranch(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-5">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5 className={'HojaBookMainColor3 mt-3'}>{Strings['Are you sure ?']}</h5>
                        <p className={'HojaBookMainColor3'}>{Strings['You want to delete this branch?']}</p>
                        <button type="button" className="btn btn-danger form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn  btn-success"
                            onClick={() => {
                                this.handleClickDelete(id)
                                this.getAllbranchBank()
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }

    // https://hojabook.com/API/bankBranches.php?type=deleteBankBranches&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&bankBranchId=2
    handleClickDelete(id) {
        fetch(window.API_URL_BRANCH, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'deleteBankBranches',
                'bankBranchId': id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),

            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            console.log(data);
            if (data['code'] == 200) {

            }
        })
    }



    reloadData() {
        this.setState({
            openleftModal: false,
            typeOfAction: '',
            data: [],
            loading: 0,
            limit: 10,
            activePage: 1,
            dataCount: 100,
        }, () => { this.getAllbranchBank() });
    }

    displayData() {
        var dataInfo = ''
        if (this.state.loading == 1 && this.state.data.length > 0) {
            dataInfo = this.state.data.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row table-header-row table-header-row-background'>
                        <Col lg={3} md={3} sm={3} xs={12} className="overflow-text-length" title={rowData['name']}>{rowData['name']}</Col>
                        <Col lg={3} md={3} sm={3} xs={12} className="overflow-text-length" title={rowData['address']}>{rowData['address']}</Col>
                        <Col lg={3} md={2} sm={2} xs={12} className="overflow-text-length" title={rowData['countryName']}>{rowData['countryName']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12} className="overflow-text-length" title={rowData['cityName']}>{rowData['cityName']}</Col>

                        <Col lg={1} md={2} sm={2} xs={12} className={'action-icon-view actionDropdownList actionDropdownListPadding-0 '}>
                            <Dropdown isOpen={(this.state.openHeader == rowData['id'] ? true : false)}
                                className="font-size-13 actionDropdownList paddingDropDown " >
                                <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList action-icon-view"
                                    caret onClick={(event) => this.openHeaderMenu(event, rowData['id'])}  >
                                    <MoreVertIcon className='menu-dots-vertical actionDropdownList' onClick={(event) => this.openHeaderMenu(event, rowData['id'])} />
                                </DropdownToggle>
                                <DropdownMenu className="font-size-13  actionDropdownList" >
                                    <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                        <DropdownItem className='actionDropdownList text-ar-right' onClick={this.toggleDrawer(true, 'Edit', rowData['id'], rowData)}
                                        >{Strings['Edit']}</DropdownItem>
                                        <DropdownItem className='actionDropdownList text-ar-right' onClick={() => { this.deleteBankBranch(rowData['id']) }} >{Strings['Delete']}</DropdownItem>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>

                    </Row>
                )
            })
        }
        return dataInfo;
    }




    getcity(id) {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getCitiesByCountryId",
                countryId: id
            }),
            headers: {
                'Accept': 'application/json',

            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data !== "Nothing found") {
                    console.log(data);

                    this.setState({
                        centerList: data['data'],


                    }, () => {
                    });

                }
            });
    }
    getAllCitiesAndCountries() {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getAllCitiesAndCountries",
            }),
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data['code'] == 200) {
                    this.setState({
                        countryList: data['data'],

                    }, () => {

                    });

                }
            });
    }
    setUpSelectedOptions = (data, type) => {
        return (<option value={data.id} key={data.id}>{data.name}</option>)
    }

    render() {
        return (
            <div>
                <Animated className='page-filter-view row'>
                    <Col md={5} sm={7} className=''>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                            <Row className='filter-form'>

                                <Col md={10} lg={10} sm={10} xs={12}>
 
                                <AvField errorMessage={Strings['This field is required']}   type="select" name="currency" value={this.state.currency} onChange={this.handleInputChange}
                                        Placeholder={Strings['please Select Currency']}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['please Select Currency']}</option>
                                        {this.state.currencyList.map((data) => this.setUpSelectedOptions(data, 'currencyList'))}
                                    </AvField>
                                </Col>

                                <Col lg={2} md={2} sm={2} xs={2} className='text-right '>
                                    <div className='report-refresh-btn btn' onClick={(event) => { this.refresh() }}>
                                        <LoopIcon />
                                    </div>
                                </Col>
                                <Col md={6} lg={6} sm={6} xs={12}>
                                <AvField errorMessage={Strings['This field is required']}  type="select" name="country" value={this.state.country} onChange={this.handleInputChange}
                                        Placeholder={Strings['please Select Country']}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['please Select Country']}</option>
                                        {this.state.countryList.map((data) => this.setUpSelectedOptions(data, 'countryList'))}
                                    </AvField>
                                </Col>
                                <Col md={6} lg={6} sm={6} xs={12}>
                                    <AvField errorMessage={Strings['This field is required']}  type="select" name="city" value={this.state.city} onChange={this.handleInputChange}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['Please Select City']}</option>
                                        {this.state.centerList.map((data) => this.setUpSelectedOptions(data, 'centerList'))}
                                    </AvField>
                                </Col>

                            </Row>



                        </AvForm>
                    </Col>
                    <Col md={7} sm={5} className='text-right text-respnsive-left text-ar-left text-respnsive-right  mt-3'>
                        <div className='report-refresh-btn HojaBookMainBackgroundColor1 HojaBookWhiteColor btn' onClick={this.toggleDrawer(true, 'Add', '', '')}>
                            {Strings['Add Branch']}
                        </div>
                    </Col>
                </Animated>


                {/* //table--start// */}

                <Row>
                    <div className='master-table-view width-100'>
                        {this.state.dataCount > 0 &&
                            <Row>
                                <Col md={12}>
                                    <div className='mt-2 d-inline-flex showEntriesStyle'>
                                        <div className='table-display-limit-text-1'>{Strings['Show']}</div>
                                        <AvForm className="form-inline table-display-limit-select">
                                            <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                <option value="10" >10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </AvField>
                                        </AvForm>
                                        <div className='table-display-limit-text-2'>{Strings['entries']} {Strings['of']}
                                            <span className='table-data-count-view'> {this.state.dataCount} </span></div>
                                    </div>

                                </Col>
                            </Row>
                        }



                        {(this.state.data.length > 0 && this.state.loading != 0) &&
                            <div>
                                <Row className='table-header-row'>
                                    <Col lg={3} md={3} sm={3} xs={12} className="overflow-text-length" title={Strings['branch name']}>{Strings['branch name']}</Col>
                                    <Col lg={3} md={3} sm={3} xs={12} className="overflow-text-length" title={Strings['Address']}>{Strings['Address']}</Col>
                                    <Col lg={3} md={2} sm={2} xs={12} className="overflow-text-length" title={Strings['Country Name']}>{Strings['Country Name']}</Col>
                                    <Col lg={2} md={2} sm={2} xs={12} className="overflow-text-length" title={Strings['City Name']}>{Strings['City Name']}</Col>
                                    <Col lg={1} md={2} sm={2} xs={12} className="overflow-text-length " title={Strings['Actions']}>{Strings['Actions']}</Col>
                                </Row>
                                {this.displayData()}
                            </div>
                        }




                        {this.state.loading == 0 &&
                            <Row>
                                <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                    <div className='card-body'>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                        <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                    </div>
                                </Col>
                            </Row>

                        }


                        {(this.state.data.length <= 0 && this.state.loading == 1) &&
                            <div className={"noDataViewMargin"}>
                                <div className={' text-center mb-5'}>
                                    <img src={toAbsoluteUrl("/media/HojaBook/logo.svg")} class="img-fluid" width={'250'} height={'auto'} />
                                </div>
                                <div className={' text-center mt-5 Font-Size-20 HojaBookMainColor1'}>
                                    {Strings['There is no data to display']}
                                </div>
                            </div>
                        }


                        {this.state.dataCount > 0 &&
                            <Col md={12}>
                                <Row className='pagenation-view mt-3 mb-4'>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.dataCount}
                                        pageRangeDisplayed={4}
                                        onChange={this.handlePageChange}
                                        prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next pagenation-view-next-back-style' : 'flaticon2-back pagenation-view-next-back-style'}></i>}
                                        nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back pagenation-view-next-back-style' : 'flaticon2-next pagenation-view-next-back-style'}></i>}
                                        hideFirstLastPages
                                        innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </Row>
                            </Col>
                        }
                    </div>
                </Row>


                <Drawer className={'form-side'} anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"} open={this.state.openleftModal} >
                    <BranchBankForm id={this.state.id} bankId={this.props.match.params.bankId} CloseDrawerFunc={this.CloseDrawerFunc}
                        reloadData={this.reloadData}
                        selectedData={this.state.selectedData}
                        typeOfAction={this.state.typeOfAction} />
                </Drawer>

            </div>
        )
    }
}
export default branchBank;

