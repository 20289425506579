import React from "react";
import { Row, Col, Button, } from 'reactstrap';
import { AvForm, AvField, } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import { CircularProgress } from "@material-ui/core";
import '../../../../node_modules/react-phone-number-input/style.css'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class CurrencyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startSave: 0,
            name:this.props.selectedData['name']?this.props.selectedData['name']:'',
            code:this.props.selectedData['code']?this.props.selectedData['code']:'',
            rate:this.props.selectedData['rate']?this.props.selectedData['rate']:'',

        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }




    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {

        })
    }




    // https://hojabook.com/API/currency.php?type=editCurrency&userId=1&token=0d669d29414e458c6be308121f40d227&
    // companyId=1&isDefault=1&name=JOD&code=JD&rate=1&currencyId=1    
    EditCurrency() {
        console.log(this.props.selectedData['defaultCurrency']);
        console.log(this.props.id);
        this.setState({ startSave: 1 })

        fetch(window.API_URL_CURRENCY , {
            method: 'POST',
            body: JSON.stringify({
                'type': 'editCurrency',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'isDefault':this.props.selectedData['defaultCurrency'],
                'name':this.state.name,
                'code':this.state.code,
                'rate':this.state.rate,
                'currencyId':this.props.id,

            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }
    // https://hojabook.com/API/currency.php?type=addCurrency&userId=1&
    // token=0d669d29414e458c6be308121f40d227&companyId=1&
    // isDefault=0&name=test3&code=TST3&rate=0.5   
    
    AddCurrency() {
        this.setState({ startSave: 1 })
        fetch(window.API_URL_CURRENCY , {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addCurrency',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'isDefault':0,
                'name':this.state.name,
                'code':this.state.code,
                'rate':this.state.rate

            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            console.log('====================================== data');
            console.log(data);
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }



    handleValidSubmit(event) {
        //event.persist();
        this.props.typeOfAction == 'Add' ? this.AddCurrency() : this.EditCurrency()
    }


    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10}>
                            <div class='drawer-header-title'>{this.props.typeOfAction == 'Add' ? Strings['Add Currency'] : Strings['Edit Currency']}</div>
                        </Col>
                        <Col md={2} className='text-right text-ar-left'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <AvForm className="bankFormBranchpadding pl-2 pr-2 formLabel " onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                                <AvField name="name" label={Strings['Name'] + ' *'} placeholder={Strings['Enter currency name']} value={this.state.name} required onChange={this.handleInputChange} />
                                <AvField name="code" label={Strings['Code'] + ' *'} placeholder={Strings['Enter currency code']} value={this.state.code} required onChange={this.handleInputChange} />
                                <AvField name="rate" label={Strings['Rate'] + ' *'} min="0.01" step="0.01" placeholder={Strings['Rate']} value={this.state.rate} required type="number" onChange={this.handleInputChange} />

                                <div className="text-right mb-5 marginTop2">
                                    {this.state.startSave == 0 ?
                                        <Button type="submit" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default" : "ml-4  btn-success submit-default"}>{Strings['Submit']}</Button>
                                        :
                                        <Button type="button" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default bt-color" : "ml-4  btn-success submit-default bt-color"}><CircularProgress className="SaveCircularProgressColor" /> {Strings['Submit']}</Button>
                                    }
                                    <Button type="button" className=" btn-danger cancel-default" onClick={this.props.CloseDrawerFunc}>{Strings['Cancel']}</Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>

                </Col>

            </div>
        );
    }
}


export default CurrencyForm;
