import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    this.logoutHandler()
  }

  logoutHandler = () => { 
    // alert(1)
    localStorage.setItem('USER_ID', '');
    localStorage.setItem('USER_EMAIL', '');
    localStorage.setItem('USER_FULL_NAME', '');
    window.location = "/auth";
  }


  render() {
    return null
    // const { hasAuthToken } = this.props;
    // return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
