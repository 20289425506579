import React from "react";
import { Row, Col, Button, } from 'reactstrap';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import { CircularProgress } from "@material-ui/core";
import '../../../../node_modules/react-phone-number-input/style.css'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class BankAccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startSave: 0,
            accountName: this.props.selectedData['accountName'] ? this.props.selectedData['accountName'] : '',
            accountNum: this.props.selectedData['accountNum'] ? this.props.selectedData['accountNum'] : '',
            swiftCode: this.props.selectedData['swiftCode'] ? this.props.selectedData['swiftCode'] : '',
            Iban: this.props.selectedData['IBAN'] ? this.props.selectedData['IBAN'] : '',
            phone: this.props.selectedData['phone'] ? this.props.selectedData['phone'] : '',
            bankBranchId: this.props.selectedData['bankBranchId'] ? this.props.selectedData['bankBranchId'] : '',
            isLeaf: this.props.selectedData['isLeaf'] == 0 ? true : false,
            accountType: this.props.selectedData['accountType'] ? this.props.selectedData['accountType'] : '',
            subAccountType: this.props.selectedData['subAccountType'] ? this.props.selectedData['subAccountType'] : '',
            bankList: [],
            bankBranchList: [],
            accountTypeList: [],
            subAccountTypeList: [],
            bankId: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }


    componentDidMount() {
        console.log(this.props.selectedData);
        this.getAllBanks();
        this.getAllAccountType();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {

            if (name == 'bankId') {
                this.getAllbranchBank(value);
            }
            if (name == 'accountType') {
                this.getAllSubAccountType(value);
            }
        })
    }



    getAllBanks() {
        this.setState({ loading: 0 })
        fetch(window.API_URL_DATA4, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllBanksByCompanyId',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'page': 'all',
                'limit': this.state.limit,
                'search': '',

            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            // console.log(data);
            if (typeof data !== 'undefined') {
                if (data['code'] == 200) {
                    this.setState({
                        bankList: data['data'],
                        loading: 1
                    });
                } else {

                }
            }
        });
    }


    getAllbranchBank(id) {
        this.setState({ loading: 0 })
        fetch(window.API_URL_BRANCH, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBankBranchesByBankIdAndCompanyId',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'page': 'all',
                'limit': this.state.limit,
                'bankId': id,
                'countryId': '',
                'cityId': '',
                'currency': '',


            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {

            return response.json();
        }).then(data => {
            // console.log(data);
            if (data['code'] == 200) {
                this.setState({
                    bankBranchList: data['data'],
                    loading: 1
                });
            } else {

            }

        });
    }



    getAllAccountType() {
        this.setState({ loading: 0 })
        fetch(window.API_URL_ACCOUNTTYPE, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllAccountType',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'page': 'all',
                'limit': this.state.limit,

            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            // console.log(data);
            if (typeof data !== 'undefined') {
                if (data['code'] == 200) {
                    this.setState({
                        accountTypeList: data['data'],
                        loading: 1
                    });
                } else {

                }
            }
        });
    }

    // https://hojabook.com/API/subAccountType.php?type=getSubAccountTypeByAccountTypeId&userId=1&token=0d669d29414e458c6be308121f40d227&
    // page=1&limit=10&accountTypeId=1   
    getAllSubAccountType(id) {
        this.setState({ loading: 0 })
        fetch(window.API_URL_SUBACCOUNT, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getSubAccountTypeByAccountTypeId',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'accountTypeId': id,
                'page': 'all',
                'limit': 10,

            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {

            return response.json();
        }).then(data => {
            // console.log(data);
            if (data['code'] == 200) {
                this.setState({
                    subAccountTypeList: data['data'],
                    loading: 1
                });
            } else {

            }

        });
    }



    EditBankAccount() {
        this.setState({ startSave: 1 })
        // fetch(window.API_URL_DATA4, {
        //     method: 'POST',
        //     body: JSON.stringify({
        //         'type': 'editBank',
        //         'name': this.state.bankName,
        //         'bankId': this.props.id,
        //         'userId': localStorage.getItem('USER_ID'),
        //         'token': localStorage.getItem('USER_TOKEN'),
        //         'companyId': localStorage.getItem('USER_COMPANYID'),
        //     }),
        //     headers: {
        //         'Accept': 'application/json',

        //     },
        // }).then(function (response) {
        //     return response.json();
        // }).then(data => {
        //     if (data['code'] == 200) {
        //         this.setState({ startSave: 0 })
        //         this.props.reloadData()

        //     } else {


        //     }
        // })

    }

    // https://hojabook.com/API/bankAccounts.php?type=addBankAccount&userId=1&companyId=1&token=0d669d29414e458c6be308121f40d227&
    // accountName=Waheed%20Test& accountNum=007878&swiftCode=045651&IBAN=1237007&
    // phone=0786840471&bankBranchId=1&
    // parentId=0&isLeaf=0&accountType=1&subAccountType=1 
    AddBankAccount() {
        console.log(window.API_URL_BANKACCOUNT);
        console.log('parentId', this.props.parentId);
        console.log('type', 'addBankAccount',
            'userId', localStorage.getItem('USER_ID'),
            'token', localStorage.getItem('USER_TOKEN'),
            'companyId', localStorage.getItem('USER_COMPANYID'),
            'accountName', this.state.accountName,
            'accountNum', this.state.accountNum,
            'swiftCode', this.state.swiftCode,
            'IBAN', this.state.Iban,
            'parentId', '',
            'phone', this.state.phone,
            'bankBranchId', this.state.bankBranchId,
            'isLeaf', this.state.isLeaf == true ? 0 : 1,
            'accountType', this.state.accountType,
            'subAccountType', this.state.subAccountType);


        this.setState({ startSave: 1 })
        fetch(window.API_URL_BANKACCOUNT, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addBankAccount',
                'name': this.state.bankName,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'accountName': this.state.accountName,
                'accountNum': this.state.accountNum,
                'swiftCode': this.state.swiftCode,
                'IBAN': this.state.Iban,
                'parentId': this.props.parentId == undefined ? 0 : this.props.parentId,
                'phone': this.state.phone,
                'bankBranchId': this.state.bankBranchId,
                'isLeaf': this.state.isLeaf == true ? 0 : 1,
                'accountType': this.state.accountType,
                'subAccountType': this.state.subAccountType,

            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            console.log('====================================== user data');
            console.log(data);
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }



    handleValidSubmit(event) {
        //event.persist();
        this.props.typeOfAction == 'Add' ? this.AddBankAccount() : this.EditBankAccount()
    }


    setUpSelectedOptions = (data, type) => {
        return (<option value={data.id} key={data.id}>{type == 'bank' ? data.bankName : (type == 'accountType' ? data.title : (type == 'SubaccountType' ? data.title : data.name))}</option>)
    }

    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10}>
                            <div class='drawer-header-title'>{this.props.typeOfAction == 'Add' ? Strings['Add Bank Account'] : Strings['Edit Bank Account']}</div>
                        </Col>
                        <Col md={2} className='text-right text-ar-left'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <AvForm className="bankFormBranchpadding pl-2 pr-2 formLabel " onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                                {/* //accountName// */}
                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['account Name'] + ' *'} placeholder={Strings['account Name']}
                                    name="accountName" required errorMessage={Strings['This field is required']} value={this.state.accountName} />
                                {/* //accountnumber// */}
                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['account Number'] + ' *'} placeholder={Strings['account Number']}
                                    name="accountNum" required errorMessage={Strings['This field is required']} value={this.state.accountNum} type='number' />

                                {/* //swiftCode// */}
                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['Swift Code'] + ' *'} placeholder={Strings['Swift Code']}
                                    name="swiftCode" required errorMessage={Strings['This field is required']} value={this.state.swiftCode} type='number' />

                                {/* //IBAN// */}
                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['IBAN'] + ' *'} placeholder={Strings['IBAN']}
                                    name="Iban" required errorMessage={Strings['This field is required']} value={this.state.Iban} type='number' />

                                {/* //Phone// */}

                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['Phone'] + ' *'} placeholder={Strings['Phone']}
                                    name="phone" required errorMessage={Strings['This field is required']} value={this.state.phone} type="number" />

                                <AvCheckboxGroup errorMessage={Strings['This field is required']} name="isLeaf" value={this.state.isLeaf}>
                                    <AvCheckbox customInput label={Strings['Is Last Level']} value="0" onChange={this.handleInputChange} />
                                </AvCheckboxGroup>


                                <AvField
                                    label={Strings['Bank Name'] + ' *'}
                                    errorMessage={Strings['This field is required']} type="select" name="bankId" value={this.state.bankId} onChange={this.handleInputChange}>
                                    <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['select bank']}</option>
                                    {this.state.bankList.map((data) => this.setUpSelectedOptions(data, 'bank'))}
                                </AvField>

                                <AvField
                                    label={Strings['branch name'] + ' *'}
                                    errorMessage={Strings['This field is required']} type="select" name="bankBranchId" value={this.state.bankBranchId} onChange={this.handleInputChange}>
                                    <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['select bank branch']}</option>
                                    {this.state.bankBranchList.map((data) => this.setUpSelectedOptions(data,))}
                                </AvField>



                                <AvField label={Strings['account Type'] + ' *'}
                                    errorMessage={Strings['This field is required']} type="select" name="accountType" value={this.state.accountType} onChange={this.handleInputChange}>
                                    <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['select account type']}</option>
                                    {this.state.accountTypeList.map((data) => this.setUpSelectedOptions(data, 'accountType'))}
                                </AvField>
                                <AvField label={Strings['sub Account'] + ' *'}
                                    errorMessage={Strings['This field is required']} type="select" name="subAccountType" value={this.state.subAccountType} onChange={this.handleInputChange}>
                                    <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['select bank branch']}</option>
                                    {this.state.subAccountTypeList.map((data) => this.setUpSelectedOptions(data, 'SubaccountType'))}
                                </AvField>

                                <div className="text-right mb-5 marginTop2">
                                    {this.state.startSave == 0 ?
                                        <Button type="submit" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default" : "ml-4  btn-success submit-default"}>{Strings['Submit']}</Button>
                                        :
                                        <Button type="button" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default bt-color" : "ml-4  btn-success submit-default bt-color"}><CircularProgress className="SaveCircularProgressColor" /> {Strings['Submit']}</Button>
                                    }
                                    <Button type="button" className=" btn-danger cancel-default" onClick={this.props.CloseDrawerFunc}>{Strings['Cancel']}</Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>

                </Col>

            </div>
        );
    }
}


export default BankAccountForm;
