/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { mdiBank,mdiCashMultiple,mdiAccountBox  } from '@mdi/js';
import Icon from '@mdi/react';
import { translations } from '../../../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/HojaBook/Layers.svg")} />
            </span>
            <span className="menu-text">{Strings['Dashboard']}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}



        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{Strings['Configurations']}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

  
        {/*begin::1 Level*/}
        
        {/* <li
         
          className={`menu-item ${getMenuItemActive("/masterTable", false)}`}
          aria-haspopup="true"
        >
           <NavLink className="menu-link" to="/masterTable">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/HojaBook/Bag1.svg")} />
            </span>
            <span className="menu-text">master Table</span>
          </NavLink>
    
        </li> */}
        {/*end::1 Level*/}

          {/*begin::1 Level*/}
        
          <li
         
         className={`menu-item ${getMenuItemActive("/User", false)}`}
         aria-haspopup="true"
       >
          <NavLink className="menu-link" to="/User">
           <span className="svg-icon menu-icon">
           <i className="menu-icon flaticon2-user"></i>
           </span>
           <span className="menu-text">{Strings['Users']}</span>
         </NavLink>
   
       </li>
       {/*end::1 Level*/}
    

  
       <li
         
         className={`menu-item ${getMenuItemActive("/Banks", false)}`}
         aria-haspopup="true"
       >
          <NavLink className="menu-link" to="/Banks">
           <span className="svg-icon menu-icon">
           <Icon path={mdiBank} />
           </span>
           <span className="menu-text">{Strings['Banks']}</span>
         </NavLink>
   
       </li>

       <li
         
         className={`menu-item ${getMenuItemActive("/Currency", false)}`}
         aria-haspopup="true"
       >
          <NavLink className="menu-link" to="/Currency">
           <span className="svg-icon menu-icon">
           <Icon path={mdiCashMultiple} />
           </span>
           <span className="menu-text">{Strings['Currency']}</span>
         </NavLink>
   
       </li>
     
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
