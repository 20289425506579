import React from "react";
import { Row, Col, Button, } from 'reactstrap';
import { AvForm, AvField, } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import { CircularProgress } from "@material-ui/core";
import '../../../../node_modules/react-phone-number-input/style.css'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class branchBankForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startSave: 0,
            countryList: [],
            centerList: [],
            currencyList: [],
            branchName: this.props.selectedData['name'] ? this.props.selectedData['name'] : '',
            country: this.props.selectedData['countryId'] ? this.props.selectedData['countryId'] : '',
            city: '',
            currency: this.props.selectedData['currency'] ? this.props.selectedData['currency'] : '',
            startSave: 0,
            Address: this.props.selectedData['address'] ? this.props.selectedData['address'] : '',
            loading: this.props.typeOfAction == 'Add' ? 0 : 1,
            typeOfAction: this.props.typeOfAction,


        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }




    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {
            if (name == 'country') {
                this.getcity(value);
            }
        }

        )

    }

    componentDidMount() {
        this.getAllCitiesAndCountries()
        this.getAllCurrancy()
    }

    getcity(id) {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getCitiesByCountryId",
                countryId: id
            }),
            headers: {
                'Accept': 'application/json',

            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data !== "Nothing found") {
                    console.log(data);

                    this.setState({
                        centerList: data['data'],


                    }, () => {
                    });

                }
            });
    }
    getAllCitiesAndCountries() {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getAllCitiesAndCountries",
            }),
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data['code'] == 200) {
                    this.setState({
                        countryList: data['data'],

                    }, () => {
                        if (this.props.id) {
                            var selectedIndex = this.state.countryList.findIndex(obj => obj.id === this.state.country)
                            this.setState({
                                centerList: this.state.countryList[selectedIndex]['cities'],
                                city: this.props.selectedData['cityId'] ? this.props.selectedData['cityId'] : '',
                                loading: 0,
                            })
                        }
                    });

                }
            });
    }
    // https://hojabook.com/API/currency.php?type=getAllCurrency&userId=1&token=0d669d29414e458c6be308121f40d227&companyId=1&page=all&limit=2
    getAllCurrancy() {
        fetch(window.API_URL_CURRENCY, {
            method: "POST",
            body: JSON.stringify({
                type: "getAllCurrency",
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'page': 'all',
                'limit': 2
            }),
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data['code'] == 200) {
                    this.setState({
                        currencyList: data['data'],

                    }, () => {

                    });

                }
            });
    }
    setUpSelectedOptions = (data, type) => {
        return (<option value={data.id} key={data.id}>{data.name}</option>)
    }

    // https://hojabook.com/API/bankBranches.php?type=editBankBranches&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&
    // name=testNew&address=amman&bankId=1&currency=1&countryId=1&cityId=1&bankBranchId=2     
    EditBankBranch() {
        this.setState({ startSave: 1 })
        fetch(window.API_URL_BRANCH, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'editBankBranches',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'name': this.state.branchName,
                'address': this.state.Address,
                'bankId': this.props.bankId,
                'currency': this.state.currency,
                'countryId': this.state.country,
                'cityId': this.state.city,
                'bankBranchId': this.props.id

            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }
    // https://hojabook.com/API/bankBranches.php?
    // type=addBankBranches&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&
    // name=test&address=amman&bankId=1&currency=1&countryId=1&cityId=1
    AddBankBranch() {
        this.setState({ startSave: 1 })
        fetch(window.API_URL_BRANCH, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addBankBranches',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
                'name': this.state.branchName,
                'address': this.state.Address,
                'bankId': this.props.bankId,
                'currency': this.state.currency,
                'countryId': this.state.country,
                'cityId': this.state.city
            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            // console.log('======================================data==========');
            // console.log(data);
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }



    handleValidSubmit(event) {
        this.props.typeOfAction == 'Add' ? this.AddBankBranch() : this.EditBankBranch()
    }


    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10} xs={10} sm={10}>
                            <div class='drawer-header-title'>{this.props.typeOfAction == 'Add' ? Strings['Add Branch'] : Strings['Edit Branch']}</div>
                        </Col>
                        <Col md={2} xs={2} sm={2} className='text-right text-ar-left'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>

                    {this.state.loading == 1 &&
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                            </div>
                        </Col>

                    }


                    {this.state.loading == 0 &&
                        <Row>
                            <Col>
                                <AvForm className="bankFormBranchpadding pl-2 pr-2 formLabel " onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                                    {/* //branch Name// */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['branch name'] + ' *'} placeholder={Strings['branch name']}
                                        name="branchName" required errorMessage={Strings['This field is required']} value={this.state.branchName} />
                                    {/* CurrencyId */}
                                    <AvField errorMessage={Strings['This field is required']} label={Strings['Currency'] + ' *'} required type="select" name="currency" value={this.state.currency} onChange={this.handleInputChange}
                                        Placeholder={Strings['please Select Currency']}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['please Select Currency']}</option>
                                        {this.state.currencyList.map((data) => this.setUpSelectedOptions(data, 'currencyList'))}
                                    </AvField>
                                    {/* countryId */}
                                    <AvField errorMessage={Strings['This field is required']} label={Strings['Country'] + ' *'} required type="select" name="country" value={this.state.country} onChange={this.handleInputChange}
                                        Placeholder={Strings['please Select Country']}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['please Select Country']}</option>
                                        {this.state.countryList.map((data) => this.setUpSelectedOptions(data, 'countryList'))}
                                    </AvField>

                                    {/* city Id */}
                                    <AvField errorMessage={Strings['This field is required']} label={Strings['City'] + ' *'} required type="select" name="city" value={this.state.city} onChange={this.handleInputChange}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['Please Select City']}</option>
                                        {this.state.centerList.map((data) => this.setUpSelectedOptions(data, 'centerList'))}
                                    </AvField>

                                    {/* address */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Address'] + ' *'} placeholder={Strings['Address']}
                                        name="Address" required errorMessage={Strings['This field is required']} value={this.state.Address} />

                                    <div className="text-right mb-5 marginTop2">
                                        {this.state.startSave == 0 ?
                                            <Button type="submit" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default" : "ml-4  btn-success submit-default"}>{Strings['Submit']}</Button>
                                            :
                                            <Button type="button" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default bt-color" : "ml-4  btn-success submit-default bt-color"}><CircularProgress className="SaveCircularProgressColor" /> {Strings['Submit']}</Button>
                                        }
                                        <Button type="button" className=" btn-danger cancel-default" onClick={this.props.CloseDrawerFunc}>{Strings['Cancel']}</Button>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    }
                </Col>

            </div>
        );
    }
}


export default branchBankForm;
