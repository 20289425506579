import React from "react";
import { Row, Col, Button,} from 'reactstrap';
import { AvForm, AvField,  } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import { CircularProgress } from "@material-ui/core";
import '../../../../node_modules/react-phone-number-input/style.css'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class bankForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startSave: 0,
            bankName:this.props.selectedData['bankName']

        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

 
  

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {

        })
    }


   

    // https://hojabook.com/API/banks.php?type=editBank&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&name=NewBanks&bankId=3
    EditBank() {
        this.setState({ startSave: 1 })

        fetch(window.API_URL_DATA4, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'editBank',
                'name': this.state.bankName,
                'bankId': this.props.id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }
    // https://hojabook.com/API/banks.php?type=addBank&companyId=1&userId=1&token=0d669d29414e458c6be308121f40d227&name=Banks
    AddBank() {
        this.setState({ startSave: 1 })
        fetch(window.API_URL_DATA4, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addBank',
                'name': this.state.bankName,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'companyId': localStorage.getItem('USER_COMPANYID'),
            }),
            headers: {
                'Accept': 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            console.log('====================================== user data');
            console.log(data);
            if (data['code'] == 200) {
                this.setState({ startSave: 0 })
                this.props.reloadData()

            } else {


            }
        })

    }



    handleValidSubmit(event) {
        //event.persist();
        this.props.typeOfAction == 'Add' ? this.AddBank() : this.EditBank()
    }


    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10}>
                            <div class='drawer-header-title'>{this.props.typeOfAction == 'Add' ? Strings['Add Bank'] : Strings['Edit Bank']}</div>
                        </Col>
                        <Col md={2} className='text-right text-ar-left'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>

            
                        <Row>
                            <Col>
                                <AvForm className="bankFormBranchpadding pl-2 pr-2 formLabel " onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                                    {/* //Bank Name// */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Bank Name'] + ' *'} placeholder={Strings['Bank Name']}
                                        name="bankName" required errorMessage={Strings['This field is required']} value={this.state.bankName} />




                                    <div className="text-right mb-5 marginTop2">
                                        {this.state.startSave == 0 ?
                                            <Button type="submit" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default" : "ml-4  btn-success submit-default"}>{Strings['Submit']}</Button>
                                            :
                                            <Button type="button" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default bt-color" : "ml-4  btn-success submit-default bt-color"}><CircularProgress className="SaveCircularProgressColor" /> {Strings['Submit']}</Button>
                                        }
                                        <Button type="button" className=" btn-danger cancel-default" onClick={this.props.CloseDrawerFunc}>{Strings['Cancel']}</Button>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                
                </Col>

            </div>
        );
    }
}


export default bankForm;
