export const getTranslation = (lang, text) => {
    return translations[lang][text];
}

export const translations = {
    'En': {
        'Login to your account': 'Login to your account',
        'Registration': 'Registration',
        'This field is required': 'This field is required',
        'Back': 'Back',
        'Next': 'Next',
        'Choose the company type': 'Choose the company type',
        'Already have an Account ?': 'Already have an Account ?',
        'Login ': ' Login',
        'Change Lang': 'Change Lang',
        'Company Type': 'Company Type',
        'Company Account': 'Company Account',
        'Account Details': 'Account Details',
        'Completed': 'Completed',
        'Welcome': 'Welcome',
        'Industrial': 'Industrial',
        'Services': 'Services',
        'Commerce': 'Commerce',
        'Import & export': 'Import & export',
        'Other': 'Other',
        'Select City': 'Select City',
        'Create Account': 'Create Account',
        'Welcom To Hoja Book': 'Welcom To Hoja Book',
        'ACCOUNTING SOFTWARE FOR BUSINESS, Seriously easy accounting for small businesses Track expenses, customise invoices, run reports and more': 'ACCOUNTING SOFTWARE FOR BUSINESS, Seriously easy accounting for small businesses Track expenses, customise invoices, run reports and more',
        'Account': 'Account',
        'Contact': 'Contact',
        'Address': 'Address',
        'Note': 'Note',
        '<< previous': '<< Previous ',
        'Next >>': 'Next >> ',
        'Full Name': 'Full Name',
        "Description": "Description",
        'Uploade  logo': 'Uploade  logo',
        'You should insert logo first': 'You should insert logo first',
        'You should insert image': 'You should insert image',
        'Email': 'Email',
        'Phone': 'Phone',
        'taxNum ': 'Tax Number ',
        'Address': 'Address',
        'Country': 'Country',
        'City': 'City',
        'Note': 'Note',
        'create': 'Create',
        'Your account has been created successfully': 'Your account has been created successfully',
        'We will contact you soon': 'We will contact you soon',
        'Show': 'Show',
        'entries': 'Entries',
        'of': 'Of',
        'multi Select': 'multi Select',
        'Select Option': 'Select Option',
        'Select One Option': 'Select One Option',
        'Name': 'Name',
        'Actions': 'Actions',
        'Show filter': 'Show filter',
        'Add': 'Add',
        'Delete': 'Delete',
        'Are you sure ?': 'Are you sure ?',
        'Yes': 'Yes',
        'Cancel': 'Cancel',
        'Email': 'Email',
        'Phone': 'Phone',
        'Date': 'Date',
        'Please Enter Full Name': 'Please Enter Full Name',
        'Please Enter Description For Company': 'Please Enter Description For Company',
        'Please Enter Email For Company': 'Please Enter Email For Company',
        'Please Enter Phone For Company': 'Please Enter Phone For Company',
        'Please Enter Tax Number For Company': 'Please Enter Tax Number For Company',
        'Please Enter Address For Company': 'Please Enter Address For Company',
        'Please Select City': 'Please Select City',
        'please Select Country': 'please Select Country',
        'Please Add Note About Company': 'Please Add Note About Company',
        'Smart, simple online accounting software for small business Track expenses, customise invoices, run reports and even more all from one place.': 'Smart, simple online accounting software for small business Track expenses, customise invoices, run reports and even more all from one place.',
        'Company Name': 'Company Name',
        'Admin details': 'Admin Details',
        "Confirm Password": 'Confirm Password',
        'Password': 'Password',
        'Enter password': 'Enter password',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Please Enter your Email': 'Please Enter your Email',
        'Password Does Not Match': 'Password Does Not Match',
        'Create Account': 'Create Account',
        'Not Register Yet': 'Not registered yet',
        'Forget password': 'Forget password',
        'Forgot your password?': 'Forgot your password?',
        'Login Account': 'Login Account',
        'Enter your email and password': 'Enter your email and password',
        'Enter your password': 'Enter your password',
        'Enter your email': 'Enter your email',
        'Wrong email or password': 'Wrong email or password',
        'Enter your email address and we will send you an email with instructions to reset your password.': 'Enter your email address and we will send you an email with instructions to reset your password.',
        'Back to': 'Back to',
        'Reset Password': 'Reset Password',
        'Email incorrect': 'Email incorrect',
        'Email Already Exist': 'Email Already Exist',
        'Email sent successfully': 'Email sent successfully',
        'Email not correct': 'Email not correct',
        'Search': 'Search',
        'mobile': 'Mobile',
        'Role': 'Role',
        'Admin': 'Admin',
        'Employee': 'Employee',
        'Super admin': 'Super admin',
        'Sales': 'Sales',
        'purchases': 'Purchases',
        'inventory Employee': 'Inventory Employee',
        'others': 'Others',
        'Edit': 'Edit',
        'There is no data to display': 'There is no data to display',
        'You want to delete this User?': 'You want to delete this User?',
        'You want to delete this bank?': 'You want to delete this bank?',
        'Add User': 'Add User',
        'Edit User': 'Edit User',
        'Display As': 'Display As',
        'Enter mobile number': 'Enter mobile number',
        'Profile Image': 'Profile Image',
        'Upload': 'Upload',
        'accounting': 'Accounting',
        'title': 'Title',
        'Male': 'Male',
        'Female': 'Female',
        'gender': 'Gender',
        'Submit': 'Submit',
        'is not a valid ': "Is not a valid ",
        'Users': 'Users',
        'Configurations': 'Configurations',
        'Dashboard': 'Dashboard',
        'My Profile': 'My Profile',
        'Logout': 'Logout',
        'Page is under Maintenance': 'Page is under Maintenance',
        "We're making the system more awesome . we'll be back shortly.": "We're making the system more awesome . we'll be back shortly.",
        'Banks': 'Banks',
        'Add Bank': 'Add Bank',
        'Bank Name': 'Bank Name',
        'Bank branch': 'Bank branch',
        'Edit Bank': 'Edit Bank',
        'branch name': 'Branch Name',
        'Country Name': 'Country Name',
        'City Name': 'City Name',
        'Add Branch': 'Add Branch',
        'Edit Branch': 'Edit Branch',
        'You want to delete this branch?': 'You want to delete this branch?',
        'Currency': 'Currency',
        'please Select Currency': 'please Select Currency',
        'Delete this Currency?': 'Delete this Currency?',
        'Add Currency': 'Add Currency',
        'Edit Currency': 'Edit Currency',
        'Search For Currency': 'Search For Currency',
        'Code': 'Code',
        'Rate': 'Rate',
        'Enter currency name': 'Enter currency name',
        'Enter currency code': 'Enter currency code',
        'bank Accounts ': 'Bank Accounts ',
        'account Name': 'Account Name',
        'account Number': 'Account Number',
        'balance': 'Balance',
        'entryDate': 'Entry Date',
        'account Type Name': 'Account Type Name',
        'sub Account Type Name': 'Sub Account Type Name',
        'sub Account':'Sub Account',
        'Add Bank Account': 'Add Bank Account',
        'Edit Bank Account': 'Edit Bank Account',
        'No': 'No',
        'Have sub account': 'Have Sub Account',
        'All': 'All',
        'Is Last Level': 'Is Last Level',
        'select bank':'Select bank',
        'select bank branch':'Select bank branch',
        'select account type':'Select Account Type',
        'Swift Code': 'Swift Code',
        'IBAN': 'IBAN',
        'Enter IBAN': 'Enter IBAN',
        'account Type':'Account Type',
        'You want to delete this Account?': 'You want to delete this Account?',
    },
    'Ar': {
        'Login to your account': 'تسجيل الدخول إلى حسابك',
        'Registration': 'تسجيل حساب',
        'This field is required': 'هذا الحقل مطلوب',
        'Next': 'التالي',
        'Choose the company type': 'اختر نوع الشركة',
        'Already have an Account ?': 'هل لديك حساب ؟',
        'Login ': ' تسجيل الدخول',
        'Change Lang': 'تغير اللغة',
        'Company Type': 'نوع الشركة',
        'Account Details': 'تفاصيل الحساب',
        'Completed': 'مكتمل',
        'Welcome': 'أهلا بك',
        'Industrial': 'صناعي',
        'Services': 'الخدمات',
        'Commerce': 'التجارة',
        'Import & export': 'الاستراد والتصدير',
        'Other': 'أخرى',
        'Select City': 'اختر المدنيه',
        'Create Account': 'إنشاء حساب',
        'Welcom To Hoja Book': 'مرحبا بكم في Hoja Book',
        'ACCOUNTING SOFTWARE FOR BUSINESS, Seriously easy accounting for small businesses Track expenses, customise invoices, run reports and more': 'برنامج محاسبة للأعمال ، محاسبة سهلة للغاية للشركات الصغيرة تتبع النفقات وتخصيص الفواتير وتشغيل التقارير والمزيد',
        'Account': 'الحساب',
        'Contact': 'التواصل',
        'Address': 'الموقع',
        'Note': 'ملاحظة',
        '<< previous': '<< السابق',
        'Next >>': 'التالي >> ',
        'Full Name': 'الاسم',
        "Description": 'الوصف',
        'Uploade  logo': 'تحميل الشعار',
        'You should insert logo first': 'يجب عليك إدخال الشعار أولاً',
        'You should insert image': 'يجب عليك إدخال صورة',
        'taxNum ': 'الرقم الضريبي',
        'Email': 'البريد الإلكتروني',
        'Phone': 'الهاتف',
        'Address': 'العنوان',
        'Country': 'الدولة',
        'City': 'المدينة',
        'Note': 'ملاحظة',
        'create': 'إنشاء',
        'Your account has been created successfully': 'تم إنشاء حسابك بنجاح',
        'We will contact you soon': 'سوف نتصل بك قريبا',
        'Show': 'عرض',
        'entries': 'من النتائج',
        'of': 'من',
        'multi Select': 'تحديد متعدد',
        'Select Option': 'اختر الخيار',
        'Select One Option': 'حدد خيارًا واحدًا',
        'Name': 'الاسم',
        'Actions': 'الإجراءات',
        'Show filter': 'عرض التصنيف',
        'Add': 'إضافة',
        'Delete': 'حذف',
        'Are you sure ?': 'هل أنت متأكد',
        'Yes': 'نعم',
        'Cancel': 'إلغاء',
        'Email': 'البريد الإلكتروني',
        'Date': 'الوقت',
        'Please Enter Full Name': 'الرجاء إدخال الاسم الكامل',
        'Please Enter Description For Company': 'الرجاء إدخال وصف للشركة',
        'Please Enter Email For Company': 'الرجاء إدخال البريد الإلكتروني للشركة',
        'Please Enter Phone For Company': 'الرجاء إدخال رقم الهاتف للشركة',
        'Please Enter Tax Number For Company': 'الرجاء إدخال الرقم الضريبي للشركة',
        'Please Enter Address For Company': 'الرجاء إدخال عنوان الشركة',
        'Please Select City': 'الرجاء تحديد المدينة',
        'please Select Country': 'الرجاء تحديد الدولة',
        'Please Add Note About Company': 'الرجاء إضافة ملاحظة عن الشركة',
        'Smart, simple online accounting software for small business Track expenses, customise invoices, run reports and even more all from one place.': 'برنامج محاسبة ذكي وبسيط عبر الإنترنت للشركات الصغيرة تتبع النفقات وتخصيص الفواتير وتشغيل التقارير والمزيد ، كل ذلك من مكان واحد.',
        'Company Name': 'اسم الشركة',
        'Admin details': 'تفاصيل المسؤول',
        'Confirm Password': 'تأكيد كلمة المرور',
        'Password': 'كلمة المرور',

        'Enter password': 'أدخل كلمة السر',
        'First Name': 'الإسم الاول',
        'Last Name': 'الإسم الأخير',
        'Please Enter your Email': 'ادخل بريدك الألكتروني',
        'Password Does Not Match': 'كلمة السر غير متطابقة',
        'Not Register Yet': 'لم تسجل بعد',
        'Forget password': 'نسيت كلمة السر',
        'Forgot your password?': 'نسيت رقمك السري؟',
        'Login Account': 'تسجيل دخول',
        'Enter your email and password': 'أدخل بريدك الإلكتروني وكلمة المرور',
        'Enter your password': 'ادخل رقمك السري',
        'Enter your email': 'أدخل بريدك الإلكتروني',
        'Wrong email or password': 'البريد الالكتروني او كلمة السر خاطئة',
        'Enter your email address and we will send you an email with instructions to reset your password.': 'أدخل عنوان بريدك الإلكتروني وسنرسل إليك رسالة بريد إلكتروني تحتوي على تعليمات لإعادة تعيين كلمة المرور الخاصة بك',
        'Back to': 'عودة إلى',
        'Reset Password': 'إعادة تعيين كلمة المرور',
        'Email incorrect': 'البريد الإلكتروني غير صحيح',
        'Email Already Exist': "البريد الالكتروني موجود مسبقا",
        'Email sent successfully': 'تم إرسال البريد الإلكتروني بنجاح',
        'Email not correct': 'البريد الإلكتروني غير صحيح',
        'Search': 'بحث',
        'mobile': 'رقم الأرضي',
        'Role': 'الصلاحية',
        'Admin': 'الإداره',
        'Employee': 'موظف',
        'Super admin': 'الإداره الرئيسيه',
        'Sales': 'المبيعات',
        'purchases': 'المشتريات',
        'inventory Employee': 'موظفين المستودع',
        'others': 'آخرون',
        'Edit': 'تعديل',
        'There is no data to display': 'لا يوجد بيانات لعرضها',
        'You want to delete this User?': 'هل تريد حذف هذا المستخدم؟',
        'Add User': 'إضافة مستخدم',
        'Edit User': 'تعديل المستخدم',
        'Display As': 'عرض باسم',
        'Profile Image': 'صورة المستخدم',
        'Upload': 'تحميل',
        'accounting': 'محاسبة',
        'title': 'العنوان',
        'Male': 'ذكر',
        'Female': 'أنثى',
        'gender': 'الجنس',
        'Submit': 'حفظ',
        'is not a valid ': 'ليس صحيحا',
        'Users': 'المستخدمين',
        'Configurations': 'أدوات',
        'Dashboard': 'لوحة التحكم',
        'My Profile': 'حسابي',
        'Logout': 'تسجيل الخروج',
        'Page is under Maintenance': 'سيتم تعديل هذه الصفحة',
        "We're making the system more awesome . we'll be back shortly.": "نحن نعمل على جعل النظام أفضل . سنعود قريبا",
        'Banks': 'البنوك',
        'Add Bank': 'أضافة بنك',
        'Bank Name': 'اسم البنك',
        'Bank branch': 'فرع البنك',
        'Edit Bank': 'تعديل البنك',
        'You want to delete this bank?': 'هل تريد حذف البنك؟',
        'branch name': 'اسم الفرع',
        'Country Name': 'اسم الدولة',
        'City Name': 'اسم المدينه',
        'Add Branch': 'أضافة الفرع',
        'Edit Branch': 'تعديل الفرع',
        'You want to delete this branch?': 'هل تريد حذف الفرع؟',
        'Company Account': 'حساب الشركة',
        'Currency': 'العملة',
        'please Select Currency': 'الرجاء تحديد العملة',
        'Delete this Currency?': 'هل تريد حذف العملة؟',
        'Add Currency': 'أضف عملة',
        'Edit Currency': 'تعديل عملة',
        'Search For Currency': 'البحث عن العملة',
        'Code': 'الرمز',
        'Rate': 'سعر العملة',
        'Enter currency name': 'أدخل اسم العملة',
        'Enter currency code': 'أدخل رمز العملة',
        'bank Accounts ': 'حسابات بنكية',
        'account Name': 'اسم الحساب',
        'account Number': 'رقم الحساب',
        'balance': 'الرصيد',
        'entryDate': 'تاريخ الانشاء',
        'account Type Name': 'اسم نوع الحساب',
        'sub Account Type Name': 'اسم نوع الحساب الفرعي',
        'Add Bank Account': 'أضافة حساب بنكي',
        'Edit Bank Account': 'تعديل حساب بنكي',
        'No': 'لا',
        'Is Last Level': 'لديه حساب فرعي',
        'All': 'الكل',
        'select bank':'اختر البنك',
        'select bank branch':'اختر فرع البنك',
        'select account type':'اختر نوع الحساب',
        'sub Account':'حساب فرعي',
        'Swift Code': 'رمز Swift',
        'Enter IBAN': 'أدخل رمز IBAN',
        'IBAN': 'IBAN',
        'account Type':'نوع الحساب',
        'You want to delete this Account?': 'هل تريد حذف الحساب؟',












    }
}
