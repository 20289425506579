import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { translations } from '../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
export function Demo1Dashboard() {
  return (
    <>
      <div className='dashboard-main-view'>
        <img className='img-fluid dashboard-logo' alt="logo" src={toAbsoluteUrl("/media/HojaBook/under.gif")} />
        <h3 className="mt-4 Hoja-Font-Bold-700 mt-5">{Strings['Page is under Maintenance']}
        </h3>
        <p className="text-muted Font-Size-14 mt-5">{Strings["We're making the system more awesome . we'll be back shortly."]}</p>

      </div>

    </>
  );
}
