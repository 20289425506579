import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Row, Col, Progress } from "reactstrap";
import { translations } from '../../../../translate.js';
import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ImageIcon from '@material-ui/icons/Image';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      companyTypeId: 1,
      activeAccounTab: 1,
      countrySelected: '',
      value: 25,
      centerList: [],
      countryList: [],
      companyType: [],


    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateAccountSubmit = this.handleCreateAccountSubmit.bind(this)
  }


  selectCompany = (companyTypeId) => {
    this.setState({ companyTypeId: companyTypeId })
  }

  setNextStep = () => {
    this.setState({ activeTab: this.state.activeTab + 1 }, () => {



    })
  }

  handleChangeLang(langId) {
    localStorage.setItem('LANG', langId);
    window.location.reload();
  }


  componentDidMount() {
    this.getCompanyType();
  }

  // https://hojabook.com/API/companies.php?type=getCompaniesTypes
  getCompanyType() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getCompaniesTypes",
      }),
      headers: {
        'Accept': 'application/json',

      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data['code'] == 200) {
          var selectedItem = []
          for (var obj in data['data']) {
            if (data['data'][obj]['active'] == true) {
              selectedItem.push(obj)
            }

          }

          this.setState({
            companyType: data['data'],
            companyTypeId: selectedItem[0]
          }, () => {
          });

        }
      });
  }

  getAllCitiesAndCountries() {
    fetch(window.API_URL_DATA2, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllCitiesAndCountries",
      }),
      headers: {
        'Accept': 'application/json',

      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data['code'] == 200) {
          this.setState({
            countryList: data['data'],

          });

        }
      });
  }

  // https://hojabook.com/API/cities.php?type=getCitiesByCountryId&countryId=1
  getcity(id) {
    fetch(window.API_URL_DATA2, {
      method: "POST",
      body: JSON.stringify({
        type: "getCitiesByCountryId",
        countryId: id
      }),
      headers: {
        'Accept': 'application/json',

      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          console.log(data);

          this.setState({
            centerList: data['data'],

          }, () => {
          });

        }
      });
  }

  //Steps----View--Start//
  displaySteps() {
    return (
      <Row className='Registration-main-steps-view'>
        <div>
          {/* //Step-1----WelcomTab */}
          <div class="wizard-steps margin-bottom3">
            <div class="wizard-wrapper ">
              <div class={this.state.activeTab == 1 ? 'wizard-icon-active' : 'wizard-icon'}>
                <span class="wizard-number">1</span>
              </div>
              <div class="wizard-label">
                <p class="wizard-title mb-0 Hoja-Font-Bold">{Strings['Welcome']}</p>
              </div>
            </div>
          </div>

          {/* //Step-2----CompanyType  */}

          <div class="wizard-steps margin-bottom3" >
            <div class="wizard-wrapper ">
              <div class={this.state.activeTab == 2 ? 'wizard-icon-active' : 'wizard-icon'}>
                <span class="wizard-number">2</span>
              </div>
              <div class="wizard-label">
                <p class="wizard-title mb-0 Hoja-Font-Bold">{Strings['Company Type']}</p>
              </div>
            </div>
          </div>

          {/* //Step-3---Account Details  */}

          <div class="wizard-steps margin-bottom3" >
            <div class="wizard-wrapper ">
              <div class={this.state.activeTab == 3 ? 'wizard-icon-active' : 'wizard-icon'}>
                <span class="wizard-number">3</span>
              </div>
              <div class="wizard-label">
                <p class="wizard-title mb-0 Hoja-Font-Bold">{Strings['Account Details']}</p>
              </div>
            </div>
          </div>

          {/* //Step-4---Admin Details  */}
          <div class="wizard-steps margin-bottom3" >
            <div class="wizard-wrapper ">
              <div class={this.state.activeTab == 4 ? 'wizard-icon-active' : 'wizard-icon'}>
                <span class="wizard-number">4</span>
              </div>
              <div class="wizard-label">
                <p class="wizard-title mb-0 Hoja-Font-Bold">{Strings['Admin details']}</p>
              </div>
            </div>
          </div>

          {/* //Step-5----Completed  */}

          <div class="wizard-steps">
            <div class="wizard-wrapper ">
              <div class={this.state.activeTab == 5 ? 'wizard-icon-active' : 'wizard-icon'}>
                <span class="wizard-number">5</span>
              </div>
              <div class="wizard-label">
                <p class="wizard-title mb-0 Hoja-Font-Bold">{Strings['Completed']}</p>
              </div>
            </div>
          </div>
        </div>

      </Row >
    )
  }
  //Steps----View--End//

  //Company----Type----View--Start//
  getTypeView() {
    let companyTypeView = ''
    var imgeCode = ''
    if (Object.keys(this.state.companyType).length > 0) {
      companyTypeView = Object.entries(this.state.companyType).map((rowData, index) => {

        return (
          <Col lg={4} md={6} key={index} className={'marginTop2'}>
            <div >
              <div onClick={() => rowData[1]['active'] == false == 1 ? null : this.selectCompany(rowData[0])}
                className={
                  rowData[1]['active'] == false ? 'main-color-1 companyType-box companyType-section-box disableCursor' :
                    (this.state.companyTypeId == rowData[0] ?
                      'companyType-section-box-selected-view main-color-1 companyType-box companyType-section-box' :
                      'main-color-1 companyType-box companyType-section-box')

                }>
                <div>
                  {rowData[0] != 5 ?
                    (
                      rowData[1]['active'] == false ?
                        (<img alt="Logo"
                          className="img-fluid imageWidthCompanyType"
                          src={
                            rowData[0] == 1 ?
                              toAbsoluteUrl("/media/HojaBook/industrial-company-disable.png") :
                              rowData[0] == 2 ?
                                toAbsoluteUrl("/media/HojaBook/suppor-disable.png") :
                                rowData[0] == 3 ?
                                  toAbsoluteUrl("/media/HojaBook/company2-disable.png")
                                  :
                                  toAbsoluteUrl("/media/HojaBook/transport-disable.png")} />)
                        :
                        (this.state.companyTypeId == rowData[0] ?
                          <img alt="Logo"
                            className="img-fluid imageWidthCompanyType"
                            src={
                              rowData[0] == 1 ?
                                toAbsoluteUrl("/media/HojaBook/industrial-company-Select.png") :
                                rowData[0] == 2 ?
                                  toAbsoluteUrl("/media/HojaBook/suppor-Select.png") :
                                  rowData[0] == 3 ?
                                    toAbsoluteUrl("/media/HojaBook/company2-Select.png")
                                    :
                                    toAbsoluteUrl("/media/HojaBook/transportSelect.png")} />
                          :

                          <img alt="Logo"
                            className="img-fluid imageWidthCompanyType"
                            src={
                              rowData[0] == 1 ?
                                toAbsoluteUrl("/media/HojaBook/industrial-company.jpg") :
                                rowData[0] == 2 ?
                                  toAbsoluteUrl("/media/HojaBook/suppor.png") :
                                  rowData[0] == 3 ?
                                    toAbsoluteUrl("/media/HojaBook/company2.png")
                                    :
                                    toAbsoluteUrl("/media/HojaBook/transport.png")} />
                        )
                    )

                    :
                    (
                      <img alt="Logo"
                        className="img-fluid imageWidthCompanyType"
                        src={
                          rowData[1]['active'] == false ?
                            toAbsoluteUrl("/media/HojaBook/other-disable.png")
                            :
                            (
                              this.state.companyTypeId == rowData[0] ?
                                toAbsoluteUrl("/media/HojaBook/other-Select.png")
                                :
                                toAbsoluteUrl("/media/HojaBook/other.png")
                            )


                        } />
                    )
                  }

                </div>
                <div className={rowData[1]['active'] == false ? 'TypeNameStyle disableColor' :
                  (this.state.companyTypeId == rowData[0] ?
                    'TypeNameStyle activeColor'
                    :
                    'TypeNameStyle'
                  )

                }>
                  {localStorage.getItem('LANG') == 1 ? rowData[1]['titleAr'] : rowData[1]['titleEn']}
                </div>
              </div>
            </div>
          </Col>

        )
      })
    }
    return companyTypeView

  }
  //Company----Type----View--End//






  ///Steps3----AccountDetails(view,functions)--start///

  progressValue() {
    if (this.state.activeAccounTab == 1) {
      var file = document.querySelector('input[name=FileDate]').files[0];
      this.setState({ fileData: file })
    }
    this.setState({
      value: this.state.value + 25,
      activeAccounTab: this.state.activeAccounTab + 1

    }, () => {
      if (this.state.activeAccounTab == 3) {
        this.getAllCitiesAndCountries()
      }

    })
  }
  previousProgressValue() {
    this.setState({
      value: this.state.value - 25,
      activeAccounTab: this.state.activeAccounTab - 1

    })
  }
  handleFileChange = (event) => {
    this.setState({ haveError: 0, })
    var fileReader = new FileReader();
    const scope = this
    this.setState({ displayErrorHeaderMsg: false });
    this.setState({ progressUploadHeader: 10 });
    if (event.target.files[0] != undefined) {
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function (data) {

        if (data.lengthComputable) {
          var progress = parseInt(((data.loaded / data.total) * 100), 10);
          scope.setState({ progressUploadHeader: progress });
        }
      }

      if (event.target.files[0]['type'].includes("image")) {
        this.setState({
          FileName: event.target.files[0]['name'],
          canSave: 1
        });



      }
      else {
        this.setState({
          FileName: Strings['You should insert image'],
          canSave: 0
        });
      }
    }
    else {
      this.setState({
        FileName: null,
        progressUploadHeader: ''
      });


    }
  }
  setUpSelectedOptions = (data, type) => {
    return (<option value={data.id} key={data.id} defaultValue={data.name}>{data.name}</option>)
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    }, () => {
      if (name == 'country') {
        var selectedIndex = this.state.countryList.findIndex(obj => obj.id === value)
        // this.getcity(value);
        this.setState({ index: selectedIndex })

      }
    }

    )

  }


  handleSubmit() {

    if (this.state.activeAccounTab == 4) {
      this.creatAccount()
    } else
      if (this.state.FileName != undefined && this.state.FileName != null && this.state.FileName != "") {
        this.progressValue()
      }
      else {
        this.setState({ haveError: 1 })

      }
  }



  getAccountDetailsView() {
    return (
      <div className=" flex-center">
        {this.state.activeAccounTab == 1 &&
          <AvForm onValidSubmit={this.handleSubmit} className={'AccountDetailsView'} ref={c => (this.form = c)}>
            <AvField className="mb-5" onChange={this.handleInputChange} name="companyName" value={this.state.companyName}
              label={Strings['Company Name']} required errorMessage={Strings['This field is required']}
              Placeholder={Strings['Please Enter Full Name']}
            />

            <AvField className="mb-5" onChange={this.handleInputChange} name="phone" value={this.state.phone}
              label={Strings['Phone']} required errorMessage={Strings['This field is required']} type="number"
              Placeholder={Strings['Please Enter Phone For Company']}

            />

            {/* //Uploade logo--------------------start// */}
            <div className="mt-5" >
              <div class="image-upload " >
                <label className="d-flex pointer-cursor" for="file-input"  >
                  <div className="FileIconBackground">
                    <SystemUpdateAltRoundedIcon className={'mr-1 FileIconStyle'} />
                  </div>
                  <p className="mt-1 mb-0 uploadeTextStyle">{Strings['Uploade  logo']}</p>
                </label>
                <input id="file-input" type="file"
                  onChange={(e) => this.handleFileChange(e)} name='FileDate' />
              </div>

              {this.state.FileName != null && this.state.FileName != ' ' && this.state.FileName != undefined &&
                <div className="mt-2">
                  <div className="d-flex">
                    <div style={{ 'width': '5%' }}>
                      {this.state.canSave == 0 ?
                        <ErrorOutlineIcon className='errorColor' />
                        :
                        <ImageIcon className="basic-color mt-1" />
                      }
                    </div>
                    <div className={'mt-1'} style={{}}>
                      <span className={this.state.canSave == 0 ? "errorColor" : "mt-1 font-size-15 "}>{this.state.FileName}</span>
                    </div>
                  </div>
                </div>
              }
              {(this.state.progressUploadHeader != undefined && this.state.progressUploadHeader > 0) && (this.state.canSave == 1) &&
                <Progress color={"success"} striped value={this.state.progressUploadHeader} className='mb-1 mt-2 progress-bg-color'>
                  {this.state.progressUploadHeader} %</Progress>
              }
              {this.state.haveError == 1 &&
                <div className="mt-1">
                  <div className="d-flex">
                    <div style={{ 'width': '5%' }}>
                      <ErrorOutlineIcon className='errorColor' />
                    </div>
                    <div className={'mt-1'}>
                      <span className={"errorColor"}>{Strings['You should insert logo first']}</span>
                    </div>
                  </div>
                </div>
              }

            </div>
            {/* //Uploade logo-----------------------End// */}

            <button type="submit" className="AccountTab-Box-Style float-right margin-Top-16 Next-Step-float-left">{Strings['Next >>']}
            </button>


          </AvForm>
        }
        {this.state.activeAccounTab == 2 &&
          <AvForm onValidSubmit={this.handleSubmit} className={'AccountDetailsView margin-bottom2'} ref={c => (this.form = c)}>


            <AvField className="mb-5" onChange={this.handleInputChange} name="tax" value={this.state.tax}
              label={Strings['taxNum ']} type="number" required errorMessage={Strings['This field is required']}
              Placeholder={Strings['Please Enter Tax Number For Company']} />

            <AvField type="textarea" name="description" rows={5} required label={Strings["Description"]} className={'mt-2'}
              value={this.state.description} onChange={this.handleInputChange} errorMessage={Strings['This field is required']}
              Placeholder={Strings['Please Enter Description For Company']}

            />

            <button type="submit" className="AccountTab-Box-Style float-right margin-Top-16 Next-Step-float-left">{Strings['Next >>']}
            </button>

          </AvForm>
        }
        {this.state.activeAccounTab == 3 &&
          <AvForm onValidSubmit={this.handleSubmit} className={'AccountDetailsView margin-bottom2'} ref={c => (this.form = c)}>
            <AvField className="mb-5" onChange={this.handleInputChange} name="address" value={this.state.address}
              label={Strings['Address']} required errorMessage={Strings['This field is required']}
              Placeholder={Strings['Please Enter Address For Company']} />

            <AvField label={Strings['Country']} required type="select" name="country" value={this.state.country} onChange={this.handleInputChange}
              Placeholder={Strings['please Select Country']}>
              <option style={{ 'display': 'none' }} value="" defaultValue />
              {this.state.countryList.map((data) => this.setUpSelectedOptions(data, 'countryList'))}
            </AvField>


            <AvField label={Strings['City']} required type="select" name="city" value={this.state.city} onChange={this.handleInputChange}>
              <option style={{ 'display': 'none' }} value="" defaultValue />
              {this.state.countryList[this.state.index] != undefined &&
                this.state.countryList[this.state.index]['cities'].map((data) => this.setUpSelectedOptions(data, 'centerList'))}
            </AvField>
            <button type="submit" className="AccountTab-Box-Style float-right margin-Top-16 Next-Step-float-left">{Strings['Next >>']}
            </button>
          </AvForm>

        }
        {this.state.activeAccounTab == 4 &&
          <AvForm onValidSubmit={this.handleSubmit} className={'AccountDetailsView margin-bottom2 '} ref={c => (this.form = c)}>
            <AvField type="textarea" Placeholder={Strings['Please Add Note About Company']} name="note" rows={5} required label={Strings['Note']} className={'mt-2'}
              value={this.state.note} onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
            <button className={"btn MainButtonActionStyle2 float-ar-left "} onClick={() => this.setNextStep()} >
              {Strings['Next']}
            </button>
          </AvForm>
        }





      </div>
    )

  }




  getAccount() {
    return (
      <div className=" flex-center padding-14">

        <div className={'marginTop2'}>
          <Progress striped color={this.state.value == 25 ? "HojaBookMainBackgroundBlac" : this.state.value == 50 ?
            "HojaBookMainBackground" : this.state.value == 75 ? "HojaBookMainGrayBackground2" : "HojaBookMainGrayBackground"}
            value={this.state.value} >
            {this.state.value == 25 ? Strings['Account'] : this.state.value == 50 ? Strings['Contact'] : this.state.value == 75 ? Strings['Address'] : Strings['Note']}
          </Progress>
        </div>
        {/* //Progress-Step-End// */}

        {/* //Account-Form-Start// */}
        <div className="marginTop2">
          {this.getAccountDetailsView()}
        </div>



        <Row className={"AccountTab-Box-active-Style"} >
          {this.state.activeAccounTab > 1 &&
            <div className="AccountTab-Box-Style mt-3" onClick={() => this.previousProgressValue()}>{Strings['<< previous']}
            </div>
          }



        </Row>
      </div >
    )

  }

  // https://hojabook.com/API/companies.php?
  // type=addCompany&
  // fname=dania&
  // lname=mizher&
  // companyName=Istanbul%20Test&
  // email=dania@istanbul.com&
  // password=123&
  // phone=0771234567& 
  // description=test&
  // address=amman&// companyType=3&// taxNum=123&
  // logo=&// note=SSS&// countryId=1&// cityId=1
  handleCreateAccountSubmit() {
    this.setState({
      passwordNotMatch: false,loginError:''
    })

    if (this.state.password != '' && this.state.password == this.state.confirmPassword) {
      var data = new FormData();


      // console.log("data", this.state.fileData);
      // console.log("type", 'addCompany');

      // console.log("companyName", this.state.companyName);
      // console.log("description", this.state.description);
      // console.log("address", this.state.address);
      // console.log('companyType', this.state.companyTypeId);
      // console.log("note", this.state.note);
      // console.log("countryId", this.state.country);
      // console.log("cityId", this.state.city);
      // console.log("phone", this.state.phone);
      // console.log("taxNum", this.state.tax);

      // console.log("fname", this.state.fName);
      // console.log('lname', this.state.lName);
      console.log("email", this.state.email);
      // console.log("password", this.state.password);
      ///////////////////////////////////////////

      data.append("data[]", this.state.fileData);
      data.append("type", 'addCompany');

      data.append("companyName", this.state.companyName);
      data.append("description", this.state.description);
      data.append("address", this.state.address);
      data.append('companyType', this.state.companyTypeId);
      data.append("note", this.state.note);
      data.append("countryId", this.state.country);
      data.append("cityId", this.state.city);
      data.append("phone", this.state.phone);
      data.append("taxNum", this.state.tax);

      data.append("fname", this.state.fName);
      data.append('lname', this.state.lName);
      data.append("email", this.state.email);
      data.append("password", this.state.password);

      fetch(window.API_URL_DATA, {
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',

        },
      }).then(function (response) {
        return response.json();
      }).then(data => {
        if (data !== "Nothing found") {
          console.log('====================================== user data');
          console.log(data);
          if (data['code'] == 200) {
            this.setNextStep()

          }
          else {
            this.setState({ loginError: Strings['Email Already Exist'] })
          }


        }
      })
      
    }
    else {
      this.setState({
        passwordNotMatch: true
      })
    }

  }

  getAdminDetailsView() {
    return (
      <div className=" flex-center">

        <AvForm onValidSubmit={this.handleCreateAccountSubmit} className={'AccountDetailsView'} ref={c => (this.form = c)}>
          <Row>
            <Col xs="12" sm="6">
              <AvField className="mb-5" onChange={this.handleInputChange} name="fName" value={this.state.fName}
                label={Strings['First Name']} required errorMessage={Strings['This field is required']}
                Placeholder={Strings['First Name']}
              />
            </Col>
            <Col xs="12" sm="6">
              <AvField className="mb-5" onChange={this.handleInputChange} name="lName" value={this.state.lName}
                label={Strings['Last Name']} required errorMessage={Strings['This field is required']}
                Placeholder={Strings['Last Name']}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <AvField className="mb-5" onChange={this.handleInputChange} name="email" value={this.state.email}
                label={Strings['Email']} required errorMessage={Strings['This field is required']} type="email"
                Placeholder={Strings['Please Enter your Email']}

              />
            </Col>
            <Col xs="12" sm="6">
              <AvField autoComplete="new-password" type='password' name="password" label={Strings['Password']}
                placeholder={Strings['Enter password']} required value={this.state.password} onChange={this.handleInputChange} />
            </Col>


          </Row>
          <Col xs="12" sm="6" className={localStorage.getItem('LANG') == 1 ? "pr-0" : "pl-0"}>

            <AvField autoComplete="new-password" type='password' name="confirmPassword"
              label={Strings['Confirm Password']} required placeholder={Strings['Confirm Password']} onChange={this.handleInputChange} />
          </Col>




          <button type="submit" className={"btn MainButtonActionStyle2 float-ar-left "}  >
            {Strings['Next']}
          </button>
        </AvForm>


        {this.state.passwordNotMatch == true &&
          <div className="mt-1">
            <div className="d-flex">
              <div style={{ 'width': '5%' }}>
                <ErrorOutlineIcon className='errorColor' />
              </div>
              <div className={'mt-1'}>
                <span className={"errorColor"}>{Strings['Password Does Not Match']}</span>
              </div>
            </div>
          </div>
        }

        {this.state.loginError != null && this.state.loginError != '' && this.state.loginError != undefined &&
          <div className="mt-1">
            <div className="d-flex">
              <div style={{ 'width': '5%' }}>
                <ErrorOutlineIcon className='errorColor' />
              </div>
              <div className={'mt-1'}>
                <span className={"errorColor"}>{this.state.loginError}</span>
              </div>
            </div>
          </div>
        }




      </div>
    )

  }

  getAdminAccount() {
    return (
      <div className=" flex-center ">

        <div className="marginTop2">
          {this.getAdminDetailsView()}
        </div>


      </div >
    )
  }
  ///Steps3----AccountDetails(view,functions)--End///





  render() {
    return (
      <div className="col-md-12 col-lg-12 col-12 col-xl-12 Registration">
        <Row className="login-form login-Registration shadow-lg" id="kt_login_signin_form">


          {/* --------------firstSection------------------ */}
          <Col lg={4} className={'HojaBookWhiteBackgroundColor'} >
            <div className={'padding-40 mt-2 '}>
              <div className="">
                <img width={200} alt="Logo" className="img-fluid login-logo marginBottom3" src={toAbsoluteUrl("https://hoja360.com/images/logo.svg")} />
              </div>

              {/* //------------------StepSection-------------------- //*/}
              {this.displaySteps()}
              {/* //------------------StepSection-------------------- //*/}

            </div>
            <div className={localStorage.getItem('LANG') == 1 ? 'ar-margin-top-1' : 'marginTop4'}>
              <p className="WelecomTitleText2 text-center mt-5 mb-1">{Strings['Already have an Account ?']}
                <Link to="/auth/login" className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor'}>{Strings['Login ']}</Link>
              </p>
              <hr className='inv-hr-view-3' />
              <p className="WelecomTitleText2 text-center mt-1 ">{Strings['Change Lang']} /
                <span className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor'} onClick={() => this.handleChangeLang(localStorage.getItem('LANG') == 1 ? 2 : 1)}>
                  {localStorage.getItem('LANG') == 2 ? 'عربي' : ' EN'}
                </span>
              </p>
            </div>
          </Col>


          {/* --------------SecondSection------------------ */}

          <Col lg={8} className={'Registration-right-screen padding-40'}>


            {this.state.activeTab == 1 &&
              <div className="text-center">
                <img alt="Logo" height={"300"} className="img-fluid login-logo marginBottom1 wlecomHoja-height300" src={toAbsoluteUrl("/media/HojaBook/Spreadsheets-bro.png")} />
                <div className="padding-20">
                  <p className="WelecomTitleText text-center Hoja-Font-Bold">{Strings['Welcom To Hoja Book']}</p>
                  <p className="WelecomTitleText2 text-center">
                    {Strings['Smart, simple online accounting software for small business Track expenses, customise invoices, run reports and even more all from one place.']}
                  </p>
                </div>
              </div>
            }



            {this.state.activeTab == 2 &&
              <div >
                <p className="CompanyTypeMainText text-left Hoja-Font-Bold mb-0 text-ar-right">{Strings['Choose the company type']}</p>
                <hr className='inv-hr-view-2' />
                <Row className='justify-content-center'>
                  {this.getTypeView()}
                </Row>

              </div>
            }


            {this.state.activeTab == 3 &&
              <div >
                <p className="CompanyTypeMainText text-left Hoja-Font-Bold mb-0 text-ar-right">{Strings['Account Details']}</p>
                <hr className='inv-hr-view-2' />
                {this.getAccount()}
              </div>
            }



            {this.state.activeTab == 4 &&
              <div >
                <p className="CompanyTypeMainText text-left Hoja-Font-Bold mb-0 text-ar-right">{Strings['Admin details']}</p>
                <hr className='inv-hr-view-2' />
                {this.getAdminAccount()}
              </div>
            }


            {this.state.activeTab == 5 &&
              <div className="text-center marginTop3">
                <img alt="Logo" className="img-fluid login-logo marginBottomImage height-succ-300" src={toAbsoluteUrl("/media/HojaBook/succ.gif")} />
                <div className="marginTop2">
                  <p className="WelecomTitleText text-center Hoja-Font-Bold">{Strings['Your account has been created successfully']}</p>
                  <p className="WelecomTitleText2 text-center">{Strings['We will contact you soon']}
                  </p>
                </div>
              </div>
            }

            {(this.state.activeTab != 4 && this.state.activeTab != 3) &&
              <button type='button' className={"btn MainButtonActionStyle float-ar-left"} onClick={() => this.setNextStep()}>
                {Strings['Next']}
              </button>
            }
          </Col>


        </Row>
      </div>
    )
  }
}

export default injectIntl(connect(null, auth.actions)(Registration));
