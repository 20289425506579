import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route,useLocation  } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import masterTable from "./pages/master/masterTable";
import User from "./pages/Users/User"
import Banks   from "./pages/Banks/Banks"
import BranchBank   from "./pages/Banks/BranchBank"
import CompanyAccount from './pages/CompanyAccount'
import Currency from './pages/Currency/Currency'
import BankAccount from './pages/BankAccount/BankAccount'
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const location = useLocation();
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
   console.log(location.pathname)
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/User" component={User} />
        <Route path="/Banks" component={Banks} />
        <Route path="/BranchBank/:bankId?/:bankTitle?" component={BranchBank} />

        <Route path="/masterTable" component={masterTable} />
        <Route path="/Currency" component={Currency} />

        
        <Route path="/BankAccount/:parentId?" component={BankAccount} />

        <Route path="/CompanyAccount" component={CompanyAccount} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
