/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { translations } from '../../../../../translate.js'

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
        
          <span className="symbol symbol-35 symbol-light-user">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {localStorage.getItem('USER_FULL_NAME')[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        
     
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/dashboard" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className={localStorage.getItem('LANG') == 2 ?"navi-icon mr-2":"navi-icon ml-2"}>
                <i className="flaticon2-calendar-3 text-success-profile" />
              </div>
              <div className="navi-text text-right text-EN-left">
                <div className="font-weight-bold cursor-pointer">
               {Strings['My Profile']}
                </div>
              
              </div>
            </div>
          </Link>
        

        

      
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-Signout font-weight-bold"
            > {Strings['Logout']}
            </Link>
           
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
