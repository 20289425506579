if (window.location.hostname == 'localhost') {
    window.HTTP_ROOT_PATH = 'https://hojabook.com/API/'
} else {
    window.HTTP_ROOT_PATH = 'https://' + window.location.hostname + '/API/' 
}

window.API_URL_DATA = window.HTTP_ROOT_PATH + "companies.php";
window.API_URL_DATA2 = window.HTTP_ROOT_PATH + "cities.php";
window.API_URL_DATA3 = window.HTTP_ROOT_PATH + "users.php";
window.API_URL_DATA4 = window.HTTP_ROOT_PATH + "banks.php";
window.API_URL_BRANCH= window.HTTP_ROOT_PATH + "bankBranches.php";
window.API_URL_CURRENCY= window.HTTP_ROOT_PATH + "currency.php";
window.API_URL_BANKACCOUNT= window.HTTP_ROOT_PATH + "bankAccounts.php";
window.API_URL_ACCOUNTTYPE= window.HTTP_ROOT_PATH + "accountTypes.php";
window.API_URL_SUBACCOUNT= window.HTTP_ROOT_PATH + "subAccountType.php";