import React, { Component } from "react";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button } from "reactstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { translations } from '../../../../translate.js';
import { Link } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: '',
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleChangeLang(langId) {
    localStorage.setItem('LANG', langId);
    window.location.reload();
  }
  // https://hojabook.com/API/users.php?type=forgetPassword&email=waheed.rumaneh@toreed.com  
  handleValidSubmit(event) {
    console.log(window.API_URL_DATA3);
    event.preventDefault();
    this.setState({ loginError: '' })
    fetch(window.API_URL_DATA3, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'forgetPassword',
        'email': this.state.email,
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data['code'] == 200) {
        this.setState({ loginError:Strings['Email sent successfully'] })

      }
      else {
        this.setState({ loginError: Strings['Email not correct'] })
      }
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    })
  }

  render() {
    return (

      <div className="col-md-12 col-lg-12 col-12 col-xl-12 login-login-page1">
        <Row className="login-form login-login-page " id="kt_login_signin_form">
          <Col lg={12} className={'align-self-end'} >
            <Row className="justify-content-between login-header-padding">
              <img width={150} alt="Logo" className="img-fluid login-logo marginBottom1" src={toAbsoluteUrl("https://hoja360.com/images/logo.svg")} />
              <div className={localStorage.getItem('LANG') == 1 ? 'ar-margin-top-1' : 'ar-margin-top-1'}>
                <p className="WelecomTitleText3 text-center mb-1">{Strings['Back to']} /
                  <Link to="/auth/Login" className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor Font-Size-14'}> {Strings['Login ']}</Link>
                </p>
              </div>
            </Row>
          </Col>
          <Col lg={6} className={'align-self-end text-center login-login-page4 '} >
            <img alt="Logo" className="img-fluid login-logo login-image" src={toAbsoluteUrl("/media/HojaBook/Security On-amico.png")} />
          </Col>
          <Col lg={6} className={' align-self-center login-form-padding login-login-page4 marginTop2 '} >
            <p className="WelecomTitleText  Hoja-Font-Bold">
              {Strings['Forget password']}
            </p>
            <p className="text-muted font-weight-bold">
              {Strings['Enter your email address and we will send you an email with instructions to reset your password.']}
            </p>


            <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)} className=' loginView forgetView'>
              <AvField type="email" name="email" label={Strings['Email']} placeholder={Strings['Enter your email']} value={this.state.email} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
              {this.state.loginError != null && this.state.loginError != '' && this.state.loginError != undefined &&
                <div className='HojaBookMainColor Hoja-Font-Bold '>
                  {/* <CheckCircleIcon className="ForgetIcon"/> */}
                  {this.state.loginError}</div>
              }

              <div className='text-right text-ar-left marginTop2'>
             
                <Button type="submit" className="btn main-button Font-Size-16">{Strings['Reset Password']}</Button>
              </div>
              <hr className='inv-hr-view-4' />

              <p className="WelecomTitleText2 text-center mt-1 ">{Strings['Change Lang']} /
                <span className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor'} onClick={() => this.handleChangeLang(localStorage.getItem('LANG') == 1 ? 2 : 1)}>
                  {localStorage.getItem('LANG') == 2 ? 'عربي' : ' EN'}
                </span>
              </p>

            </AvForm>

          </Col>
        </Row>
      </div>


    )
  }
}
export default ForgotPassword;

