import React, { Component } from "react";
import { Row, Col, CustomInput, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Button, Drawer } from "@material-ui/core";
import Pagination from "react-js-pagination";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoopIcon from '@material-ui/icons/Loop';
import { Animated } from "react-animated-css";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MasterForm from "./masterForm";

import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class masterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFiliter: false,
            openleftModal: false,
            data: [
                {
                    id: 1,
                    name: 'dania',
                    email: 'daniamiz@outlook.sa',
                    phone: '07900000000',
                    city: 'Amman',
                    country: 'jordan',
                    note: 'note',
                    TaxNum: '0000000',

                },
                {
                    id: 2,
                    name: 'dania',
                    email: 'daniamiz@outlook.sa',
                    phone: '07900000000',
                    city: 'Amman',
                    country: 'jordan',
                    note: 'note',
                    TaxNum: '0000000',
                },
                {
                    id: 3,
                    name: 'dania',
                    email: 'daniamiz@outlook.sa',
                    phone: '07900000000',
                    city: 'Amman',
                    country: 'jordan',
                    note: 'note',
                    TaxNum: '0000000',
                },
                {
                    id: 4,
                    name: 'dania',

                    email: 'daniamiz@outlook.sa',
                    phone: '07900000000',
                    city: 'Amman',
                    country: 'jordan',
                    note: 'note',
                    TaxNum: '0000000',
                }
            ],
            multiSelectData: [
                {
                    label: "multi Select 1",
                    id: 1,
                },

                {
                    label: "multi Select 2",
                    id: 2,
                },
                {
                    label: "multi Select 3",
                    id: 3,
                },
            ],
            multiSelectOption: [],
            SelectData: [
                {
                    label: "Select 1",
                    id: 1,
                },

                {
                    label: "Select 2",
                    id: 2,
                },
                {
                    label: "Select 3",
                    id: 3,
                },
            ],
            SelectOption: [],
            loading: 0,
            limit: 10,
            activePage: 1,
            dataCount: 100,
            optionDataList: [{
                name: "optionData 1",
                id: 1,
            },

            {
                name: "optionData 2",
                id: 2,
            },
            {
                name: "optionData 3",
                id: 3,
            },],

        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            //call Aoi
        })
    }

    componentDidMount() {

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            //call Api
        });
    }


    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {
            //call Api
        });
    }

    setUpSelectedOptions = (data, type) => {

        return (<option value={data.id} key={data.id}>{data.name}</option>)


    }


    deleteRow(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-5">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5 className={'HojaBookMainColor3 mt-3'}>{Strings['Are you sure ?']}</h5>
                        <p className={'HojaBookMainColor3'}>{Strings['You want to send sms ?']}</p>
                        <button type="button" className="btn btn-success form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn btn-danger "
                            onClick={() => {
                                //delete Api
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }


    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }
    toggleDrawer = (open) => (event) => {
            this.setState({ openleftModal: open, errorFlag: false }, function () {
            });
       
    };

    CloseDrawerFunc = () => {
        this.setState({
            openleftModal: false
        });
      };
    
    displayData() {
        var dataInfo = ''
        if (this.state.loading == 0 && this.state.data.length > 0) {
            dataInfo = this.state.data.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row table-header-row table-header-row-background'>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={'name'}>{rowData['name']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length " >{rowData['email']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length " >{rowData['phone']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" >{rowData['city']}</Col>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{rowData['country']}</Col>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{rowData['note']}</Col>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{rowData['TaxNum']}</Col>
                        <Col lg={1} md={3} sm={4} xs={12} className={'action-icon-view actionDropdownList actionDropdownListPadding-0 text-center text-EN-left'}>
                            <Dropdown isOpen={(this.state.openHeader == rowData['id'] ? true : false)}
                                className="font-size-13 actionDropdownList " >
                                <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList action-icon-view"
                                    caret onClick={(event) => this.openHeaderMenu(event, rowData['id'])}  >
                                    <MoreVertIcon className='menu-dots-vertical actionDropdownList' onClick={(event) => this.openHeaderMenu(event, rowData['id'])} />
                                </DropdownToggle>
                                <DropdownMenu className="font-size-13  actionDropdownList" >
                                    <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                        <DropdownItem className='actionDropdownList' onClick={this.toggleDrawer(true)}
                                        >{Strings['Add']}</DropdownItem>
                                        <DropdownItem className='actionDropdownList' onClick={() => { this.deleteRow(rowData['id']) }} >{Strings['Delete']}</DropdownItem>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>

                    </Row>
                )
            })
        }
        return dataInfo;
    }


    handleChangepicky = (type, value, event) => {
        this.setState(state => {
            return {
                [type]: value
            };
        }, function () {
            // call main fun 

        })




    }





    refresh() {
        this.setState({
            showFiliter: !this.state.showFiliter

        }, () => {
            // this.getUser()
        })
    }
    render() {
        return (
            <div>
                <Animated className='page-filter-view row'>
                    <Col md={5}>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row className='filter-form'>
                                <Col md={10}>
                                    <AvField name="searchKey" type='text' placeholder={Strings['Search']} value={this.state.searchKey} onChange={this.handleInputChange} />
                                </Col>
                                <Col md={2} lg={2} className='text-right report-left-text'>
                                    <div className='report-refresh-btn btn'>
                                        <LoopIcon />
                                    </div>
                                </Col>
                            </Row>

                            <Animated className={this.state.showFiliter == false ? 'hideFiliterSection' : 'showFiliterSection'} animationIn="fadeInDown" animationOut="fadeOut" isVisible={this.state.showFiliter}>

                                <Row>
                                    <Col className={"dateStyle"} md={6}>
                                        <AvField label={Strings['From Date']} name="fromDate" placeholder={Strings['From Date']} value={this.state.fromDate} onChange={this.handleInputChange} type='date'
                                            errorMessage={Strings['This field is required']}
                                        />
                                    </Col>
                                    <Col className={"dateStyle"} md={6}>
                                        <AvField
                                            errorMessage={this.state.toDate < this.state.fromDate && Strings['is not a valid ']}
                                            min={this.state.fromDate} label={Strings['To Date']} name="toDate" placeholder={Strings['To Date']} value={this.state.toDate} onChange={this.handleInputChange} type='date' />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className={''}>
                                        <AvField type="select" name="optionData" value={this.state.optionData} onChange={this.handleInputChange}>
                                            <option style={{
                                                'display': 'none'
                                            }} value="" defaultValue>{Strings['Select Option']}</option>
                                            {this.state.optionDataList.map((data) => this.setUpSelectedOptions(data))}
                                        </AvField>
                                    </Col>
                                    <Col className={"Picky-FormStyle marginBittom-1 "} md={6}>
                                        <Picky
                                            value={this.state.SelectOption}
                                            options={this.state.SelectData}
                                            onChange={this.handleChangepicky.bind(this, 'SelectOption')}
                                            open={false}
                                            valueKey="id"
                                            labelKey="label"
                                            multiple={false}
                                            includeSelectAll={true}
                                            includeFilter={true}
                                            dropdownHeight={200}
                                            placeholder={Strings['Select One Option']}
                                            filterPlaceholder={Strings['Select Option']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className={''}>
                                        <AvField name="referenceNo" type='number' placeholder={Strings['Reference #']} value={this.state.referenceNo} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col className={"Picky-FormStyle marginBittom-1 "} md={6}>

                                        <Picky
                                            value={this.state.multiSelectOption}
                                            options={this.state.multiSelectData}
                                            onChange={this.handleChangepicky.bind(this, 'multiSelectOption')}
                                            open={false}
                                            valueKey="id"
                                            labelKey="label"
                                            multiple={true}
                                            includeSelectAll={true}
                                            includeFilter={true}
                                            dropdownHeight={200}
                                            placeholder={Strings['multi Select']}
                                            filterPlaceholder={Strings['Select Option']}
                                        />
                                    </Col>
                                </Row>
                            </Animated>



                        </AvForm>
                    </Col>
                    <Col md={7} className='text-right report-left-text'>

                        <div className='report-refresh-btn HojaBookMainBackgroundColor HojaBookWhiteColor btn' onClick={(event) => { this.refresh() }}>
                            {Strings['Show filter']}
                        </div>
                    </Col>
                </Animated>


                {/* //table--start// */}

                <Row>
                    <div className='master-table-view width-100'>

                        <Row>
                            <Col md={12}>
                                <div className='mt-2 d-inline-flex showEntriesStyle'>
                                    <div className='table-display-limit-text-1'>{Strings['Show']}</div>
                                    <AvForm className="form-inline table-display-limit-select">
                                        <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                            <option value="10" >10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </AvField>
                                    </AvForm>
                                    <div className='table-display-limit-text-2'>{Strings['entries']} {Strings['of']}
                                        <span className='table-data-count-view'> {this.state.dataCount} </span></div>
                                </div>

                            </Col>
                        </Row>
                        <Row className='table-header-row'>
                            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Name']}>{Strings['Name']}</Col>
                            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['Email']}</Col>
                            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['Phone']}</Col>
                            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['City']}</Col>
                            <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['Country']}</Col>
                            <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['Note']}</Col>
                            <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" >{Strings['taxNum ']}</Col>
                            <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Actions']}>{Strings['Actions']}</Col>


                        </Row>
                        {this.displayData()}

                        <Row>

                            <Col md={12}>
                                <Row className='pagenation-view mt-3 mb-4'>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.dataCount}
                                        pageRangeDisplayed={4}
                                        onChange={this.handlePageChange}
                                        prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next pagenation-view-next-back-style' : 'flaticon2-back pagenation-view-next-back-style'}></i>}
                                        nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back pagenation-view-next-back-style' : 'flaticon2-next pagenation-view-next-back-style'}></i>}
                                        hideFirstLastPages
                                        innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </Row>


                <Drawer className={'form-side'} anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"} open={this.state.openleftModal} >
                    {/* <div>ddd</div> */}
                    <MasterForm CloseDrawerFunc={this.CloseDrawerFunc} />
                </Drawer>

            </div>
        )
    }
}
export default masterTable;

