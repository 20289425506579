import React, { Component } from "react";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button } from "reactstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { translations } from '../../../../translate.js';
import { Link } from "react-router-dom";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: '',
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleChangeLang(langId) {
    localStorage.setItem('LANG', langId);
    window.location.reload();
  }
  // https://hojabook.com/API/users.php?type=getUserByEmailAndPass&email=dania.mizher@toreed.com&password=123
  handleValidSubmit(event) {
    event.preventDefault();
    this.setState({ loginError: '' })
    fetch(window.API_URL_DATA3, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getUserByEmailAndPass',
        'email': this.state.email,
        'password': this.state.password,
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data['code'] == 200) {
        localStorage.setItem('USER_ID', data['data']['id']);
        localStorage.setItem('USER_TOKEN', data['data']['token']);
        localStorage.setItem('USER_FULL_NAME', data['data']['fname'] + ' ' + data['data']['lname']);
        localStorage.setItem('USER_EMAIL', data['data']['email']);
        localStorage.setItem('USER_COMPANYID', data['data']['companyId']);
        if (localStorage.getItem('LANG') == undefined || localStorage.getItem('LANG') == "" || localStorage.getItem('LANG') == null) {
          localStorage.setItem('LANG', 2);
        }
        
        window.location = "/dashboard";

      }
      else {
        this.setState({ loginError: Strings['Wrong email or password'] })
      }
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    })
  }

  render() {
    return (

      <div className="col-md-12 col-lg-12 col-12 col-xl-12 login-login-page1">
        <Row className="login-form login-login-page " id="kt_login_signin_form">
          <Col lg={12} className={'align-self-end'} >
            <Row className="justify-content-between login-header-padding">
              <img width={150} alt="Logo" className="img-fluid login-logo marginBottom3" src={toAbsoluteUrl("https://hoja360.com/images/logo.svg")} />
              <div className={localStorage.getItem('LANG') == 1 ? 'ar-margin-top-1' : 'ar-margin-top-1'}>
                <p className="WelecomTitleText3 text-center mb-1">{Strings['Not Register Yet']} /
                  <Link to="/auth/Registration" className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor Font-Size-14'}> {Strings['Create Account']}</Link>
                </p>
              </div>
            </Row>
          </Col>
          <Col lg={6} className={'align-self-end text-center login-login-page4'} >
            <img alt="Logo" className="img-fluid login-logo login-image" src={toAbsoluteUrl("/media/HojaBook/Tax.gif")} />
          </Col>
          <Col lg={6} className={' align-self-center login-form-padding login-login-page4 '} >
            <p className="WelecomTitleText  Hoja-Font-Bold">
              {Strings['Login Account']}
            </p>
            <p className="text-muted font-weight-bold">
              {Strings['Enter your email and password']}
            </p>


            <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)} className=' loginView'>
              <AvField type="email" name="email" label={Strings['Email']} placeholder={Strings['Enter your email']} value={this.state.email} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
              <AvField className="mb-1" type="password" name="password" label={Strings['Password']} placeholder={Strings['Enter your password']} value={this.state.password} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
              {this.state.loginError != null && this.state.loginError != '' && this.state.loginError != undefined &&
                <div className=''><i className='flaticon2-exclamation error-icon mr-1' />
                  {this.state.loginError}</div>
              }

              <div className='row justify-content-between pl-3 pr-3'>
              <Link to="/auth/forgot-password" className="HojaBookMainColor  font-weight-bold mt-3 ">
                  {Strings['Forgot your password?']}
                </Link>
                <Button type="submit" className="btn main-button Font-Size-16">{Strings['Login ']}</Button>
              </div>
              <hr className='inv-hr-view-4' />

              <p className="WelecomTitleText2 text-center mt-1 ">{Strings['Change Lang']} /
                <span className={'HojaBookMainColor Hoja-Font-Bold pointer-cursor'} onClick={() => this.handleChangeLang(localStorage.getItem('LANG') == 1 ? 2 : 1)}>
                  {localStorage.getItem('LANG') == 2 ? 'عربي' : ' EN'}
                </span>
              </p>

            </AvForm>

          </Col>
        </Row>
      </div>


    )
  }
}
export default Login;

