/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useLang, setLanguage } from "../../../../i18n";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const languages = [
  {
    id: 2,
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/260-united-kingdom.svg")
  },
  {
    id: 1,
    lang: "ar",
    name: "عربي",
    flag: toAbsoluteUrl("/media/flags/260-united-kingdom.svg")
  },
];
function handleChangeLang(langId) {
  localStorage.setItem('LANG', langId);
  window.location.reload();
}

class LanguageSelectorDropdown extends React.Component {

  handleChangeLang(langId) {
    localStorage.setItem('LANG', langId);
    window.location.reload();
  }

  render() {
    const { lang, iconType, setLanguage } = this.props;
    const currentLanguage = languages.find(x => x.lang === lang);
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
    
          <div className="btn btn-icon langIcon btn-dropdown btn-lg mr-1">
          <i className="flaticon2-world " />

          {/* {localStorage.getItem('LANG') == 2 && <div className='lang-box-style'>En</div>}
            {localStorage.getItem('LANG') == 1 && <div className='lang-box-style-ar'>ع</div>} */}

          </div>
   
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {languages.map((language) => (
            <li
              key={language.lang}
              className={clsx("navi-item", {
                active: language.id == localStorage.getItem('LANG')
              })}
            >
              <a
                href="#"
                onClick={() =>  this.handleChangeLang(language.id)}
                className="navi-link text-ar-right"
              >
               <i className={localStorage.getItem('LANG') == 1 ?"fas fa-language ml-2 ":"fas fa-language mr-2 "} />
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
   );
  }
}
export default LanguageSelectorDropdown;

