import React from "react";
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import PhoneInput from 'react-phone-number-input';
// import SmartInput from 'react-phone-number-input/smart-input'
import '../../../../node_modules/react-phone-number-input/style.css'


let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class masterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            phoneStatusError:0,

        }
    }


    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {

        })
    }

    handleInputChangePhone=(event)=>{
        this.setState({
            phone:event
        })

    }
    handleValidSubmit(event) {
        //event.persist();
        if(this.state.phone == '' || this.state.phone == ' ' || this.state.phone == null)
        {
            this.setState({
                phoneStatusError : 1
            })
        }
    }
    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10}>
                            <div class='drawer-header-title'>Add/ Edit user</div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={6}>
                            
                        </Col>
                        <Col md={6}>
                            
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            <AvForm className="pt-4 pl-2 pr-2" onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['Name'] + ' *'}
                                    name="name" required errorMessage={Strings['This field is required']} value={this.state.name} />

                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['Phone'] + ' *'}
                                    name="phoneNumber" required errorMessage={Strings['This field is required']} value={this.state.phoneNumber} type="number" />

                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['Email'] + ' *'}
                                    name="email" required errorMessage={Strings['This field is required']} value={this.state.email} />

                                <AvField className="" onChange={this.handleInputChange}
                                    label={Strings['City'] + ' *'}
                                    name="city" required errorMessage={Strings['This field is required']} value={this.state.city} />
                                <AvField type="textarea" name="note" rows={5} required label={Strings['Note']} className={'mt-2'}
                                    value={this.state.note} onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />

                                <div className='custom-form-phone-view'>
                                <label className={this.state.phoneStatusError == 1 ?'error-label':''}>{Strings['Phone']+ ' *'}</label>
                                    <PhoneInput
                                        placeholder={Strings['Enter phone']}
                                        // inputComponent={SmartInput}
                                        defaultCountry="JO"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.handleInputChangePhone}
                                        className={this.state.phoneStatusError == 1 ? 'custom-form-phone-error' : ''}
                                    />
                                    <div className="invalid-feedback d-block">{this.state.phoneStatusError == 1 ? Strings['This field is required'] : ''}</div>
                                </div>

                                <AvField label={Strings['Date']} name="Date" placeholder={Strings['Date']} value={this.state.Date} onChange={this.handleInputChange} type='date'
                                    errorMessage={Strings['This field is required']}
                                />
                                <div className="text-right mb-5">
                                <Button className="mr-4  btn-success submit-default">Submit</Button>
                                <Button className=" btn-danger cancel-default">Cancel</Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>

                </Col>

            </div>
        );
    }
}


export default masterForm;
