import React from "react";
import { Row, Col, Button, Progress } from 'reactstrap';
import { AvForm, AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { translations } from '../../../translate.js';
import PhoneInput from 'react-phone-number-input';
import ReactLoading from "react-loading";
import { CircularProgress } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import '../../../../node_modules/react-phone-number-input/style.css'
let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let CurruntDate = year + "-" + month + '-' + date;
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class userForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileStatusError: 0,
            countryList: [],
            centerList: [],
            fname: this.props.selectedData['fname'] ? this.props.selectedData['fname'] : '',
            lname: this.props.selectedData['lname'] ? this.props.selectedData['lname'] : '',
            email: this.props.selectedData['email'] ? this.props.selectedData['email'] : '',
            phoneNumber: this.props.selectedData['phone'] ? this.props.selectedData['phone'] : '',
            mobile: this.props.selectedData['mobile'] ? this.props.selectedData['mobile'] : '',
            password: '',
            role: this.props.selectedData['role'] ? this.props.selectedData['role'] : '',
            title: this.props.selectedData['title'] ? this.props.selectedData['title'] : '',
            headerImg: '',
            profileImgPath: this.props.selectedData['image'] ? this.props.selectedData['image'] : '',
            displayAs: this.props.selectedData['displayAs'] ? this.props.selectedData['displayAs'] : '',
            country: this.props.selectedData['countryId'] ? this.props.selectedData['countryId'] : '',
            city: '',
            startSave: 0,
            Address: this.props.selectedData['address'] ? this.props.selectedData['address'] : '',
            gender: this.props.selectedData['gender'] ? this.props.selectedData['gender'] : '',
            Date: this.props.selectedData['dateOfBirth'] ? this.props.selectedData['dateOfBirth'] : '',
            loading: this.props.typeOfAction == 'Add' ? 0 : 1,
            typeOfAction: this.props.typeOfAction,
            roleList: [
                {
                    id: 1,
                    name: Strings['Super admin']
                },
                {
                    id: 2,
                    name: Strings['Admin']
                },
                {
                    id: 3,
                    name: Strings['accounting']
                }, {
                    id: 4,
                    name: Strings['others']
                },]

        }


        this.handleValidSubmit = this.handleValidSubmit.bind(this);

    }

    componentDidMount() {
        this.getAllCitiesAndCountries()
    }

    getcity(id) {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getCitiesByCountryId",
                countryId: id
            }),
            headers: {
                'Accept': 'application/json',

            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data !== "Nothing found") {
                    console.log(data);

                    this.setState({
                        centerList: data['data'],


                    }, () => {
                    });

                }
            });
    }
    getAllCitiesAndCountries() {
        fetch(window.API_URL_DATA2, {
            method: "POST",
            body: JSON.stringify({
                type: "getAllCitiesAndCountries",
            }),
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data['code'] == 200) {
                    this.setState({
                        countryList: data['data'],

                    }, () => {
                        if (this.props.id) {
                            var selectedIndex = this.state.countryList.findIndex(obj => obj.id === this.state.country)
                            this.setState({
                                centerList: this.state.countryList[selectedIndex]['cities'],
                                city: this.props.selectedData['cityId'] ? this.props.selectedData['cityId'] : '',
                                loading: 0,
                            })
                        }
                    });

                }
            });
    }
    handleImgChange = (event) => {
        var fileReader = new FileReader();
        const scope = this

        if (event.target.files[0].type.includes("image")) {
            this.setState({ displayErrorHeaderMsg: false });
            this.setState({ progressUploadHeader: 10 });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {

                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadHeader: progress });
                }
            }
        } else {
            this.setState({ displayErrorHeaderMsg: true });
        }

        this.setState({
            headerImg: event.target.files[0]['name']
        });
    }

    setUpSelectedOptions = (data, type) => {
        return (<option value={data.id} key={data.id}>{data.name}</option>)
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {

        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        }, () => {
            if (name == 'country') {
                // var selectedIndex = this.state.countryList.findIndex(obj => obj.id === value)
                this.getcity(value);
                // this.setState({ index: selectedIndex })

            }
        }

        )

    }

    handleInputChangePhone = (event) => {
        this.setState({
            mobile: event
        })

    }

    // https://hojabook.com/API/users.php?type=editUser&userId=2&token=5e38747f2d890160c8dc2c44b0f6f098&fname=test&
    // lname=test&phone=0711111111&password=123&role=1&image=&title=Admin&displayAs=AdminTest&countryId=1&cityId=1&address=amman&mobile=7894561230&gender=1&birthOfDate=1995-10-21
    EditUser() {
        this.setState({ startSave: 1 })
        var data = new FormData();
        var headerImgData = document.querySelector('input[name=profileImg]').files[0];
        if (headerImgData == undefined) {
            headerImgData = ''
        }
        data.append("type", 'editUser');
        data.append("data[]", headerImgData);
        data.append("id", this.props.id);
        data.append("fname", this.state.fname);
        data.append('lname', this.state.lname);
        data.append("email", this.state.email);
        data.append("phone", this.state.phoneNumber);
        data.append("password", this.state.password);
        data.append('companyId', localStorage.getItem('USER_COMPANYID'))
        data.append("role", this.state.role);
        data.append("title", this.state.title);
        data.append('displayAs', this.state.displayAs);
        data.append("countryId", this.state.country);
        data.append("cityId", this.state.city);
        data.append("mobile", this.state.mobile);
        data.append("address", this.state.Address);
        data.append('permission', '')
        data.append('userId', localStorage.getItem('USER_ID'))
        data.append('token', localStorage.getItem('USER_TOKEN'))
        data.append("gender", this.state.gender);
        data.append('birthOfDate', this.state.Date);
        data.append('image', this.props.selectedData['image'])
        fetch(window.API_URL_DATA3, {
            method: 'POST',
            body: data,
            headers: {
                Accept: 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['code'] == 200) {
                    this.setState({ startSave: 0 })
                    this.props.reloadData()

                } else {
                    console.log(data);
                    this.setState({ AddError: Strings['Email Already Exist'] })


                }

            }
        })








    }
    // https://hojabook.com/API/users.php?// type=addUser&// fname=test&// lname=test&
    // email=test@test.com& // phone=0711111111&password=123&// role=1&image=&companyId=1&
    // permission=&title=Admin&displayAs=AdminTest&countryId=1& // cityId=1&address=amman&mobile=7894561230&gender=1&birthOfDate=1995-10-21
    AddUser() {
        this.setState({ startSave: 1 })
        var data = new FormData();
        var headerImgData = document.querySelector('input[name=profileImg]').files[0];
        data.append("type", 'addUser');
        data.append("data[]", headerImgData);
        data.append("fname", this.state.fname);
        data.append('lname', this.state.lname);
        data.append("email", this.state.email);
        data.append("phone", this.state.phoneNumber);
        data.append("password", this.state.password);
        data.append('companyId', localStorage.getItem('USER_COMPANYID'))
        data.append("role", this.state.role);
        data.append("title", this.state.title);
        data.append('displayAs', this.state.displayAs);
        data.append("countryId", this.state.country);
        data.append("cityId", this.state.city);
        data.append("mobile", this.state.mobile);
        data.append("address", this.state.Address);
        data.append('permission', '')
        data.append('userId', localStorage.getItem('USER_ID'))
        data.append("gender", this.state.gender);
        data.append('birthOfDate', this.state.Date);

        fetch(window.API_URL_DATA3, {
            method: 'POST',
            body: data,
            headers: {
                Accept: 'application/json',

            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                console.log('====================================== user data');
                console.log(data);
                if (data['code'] == 200) {
                    this.setState({ startSave: 0 })
                    this.props.reloadData()

                } else {
                    this.setState({ AddError: Strings['Email Already Exist'] })


                }

            }
        })

    }



    handleValidSubmit(event) {
        //event.persist();
        console.log(this.state.mobile);
        if (this.state.mobile == '' || this.state.mobile == ' ' || this.state.mobile == null) {
            this.setState({
                mobileStatusError: 1
            })
        }
        else {
            this.state.typeOfAction == 'Add' ? this.AddUser() : this.EditUser()


        }
    }
    render() {
        return (
            <div >
                <Col md={12}>
                    <Row className="Drawer-header-data">
                        <Col md={10}>
                            <div class='drawer-header-title'>{this.state.typeOfAction == 'Add' ? Strings['Add User'] : Strings['Edit User']}</div>
                        </Col>
                        <Col md={2} className='text-right text-ar-left'>
                            <button className="close-drawer" onClick={this.props.CloseDrawerFunc}>
                                <i class="flaticon-cancel"></i>
                            </button>
                        </Col>
                    </Row>




                    {this.state.loading == 1 &&
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor' role='status'></div>
                                <div className='spinner-grow text-dark m-2 HojaBookMainColor3' role='status'></div>
                            </div>
                        </Col>

                    }







                    {this.state.loading == 0 &&
                        <Row>
                            <Col>
                                <AvForm className="pt-4 pl-2 pr-2 formLabel" onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                                    {/* //FirstName// */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['First Name'] + ' *'} placeholder={Strings['First Name']}
                                        name="fname" required errorMessage={Strings['This field is required']} value={this.state.fname} />


                                    {/* //LastName// */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Last Name'] + ' *'} placeholder={Strings['Last Name']}
                                        name="lname" required errorMessage={Strings['This field is required']} value={this.state.lname} />


                                    {/* //Email// */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Email'] + ' *'} placeholder={Strings['Email']}
                                        name="email" type="email" required errorMessage={Strings['This field is required']} value={this.state.email} />

                                    {/* //Phone// */}

                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Phone'] + ' *'} placeholder={Strings['Phone']}
                                        name="phoneNumber" required errorMessage={Strings['This field is required']} value={this.state.phoneNumber} type="number" />


                                    {/* //'mobile'// */}

                                    <div className='custom-form-phone-view'>
                                        <label className={this.state.mobileStatusError == 1 ? 'error-label' : ''}>{Strings['mobile'] + ' *'}</label>
                                        <PhoneInput
                                            placeholder={Strings['mobile']}
                                            defaultCountry="JO"
                                            name="mobile"
                                            value={this.state.mobile}
                                            onChange={this.handleInputChangePhone}
                                            className={this.state.mobileStatusError == 1 ? 'custom-form-phone-error mr-2 ml-2' : 'mr-2 ml-2'}
                                        />
                                        <div className="invalid-feedback d-block">{this.state.mobileStatusError == 1 ? Strings['This field is required'] : ''}</div>
                                    </div>
                                    {/* password */}
                                    <AvField autoComplete="new-password" type='password' name="password" label={Strings['Password'] + (this.state.typeOfAction == 'Edit' ? '' : ' *')}
                                        placeholder={Strings['Enter password'] } errorMessage={Strings['This field is required']} required={this.state.typeOfAction == 'Edit' ? false : true} value={this.state.password} onChange={this.handleInputChange} />

                                    {/* role */}
                                    <AvField label={Strings['Role']+ ' *'} type="select" name="role" value={this.state.role} onChange={this.handleInputChange}>
                                        <option style={{
                                            'display': 'none',
                                        }} value="" defaultValue>{Strings['Select Option']}</option>
                                        {this.state.roleList.map((data) => this.setUpSelectedOptions(data))}
                                    </AvField>

                                    {/* role */}




                                    {/* Profile Image */}

                                    <div className='mt-2 mb-5'>
                                        <label className={"custom-label "}>{Strings['Profile Image']}</label>
                                        <span className={'fileInput-span col-md-12'}>
                                            <i class="fas fa-upload UploadImageSize"></i><span className='pointer-cursor task-action-form-fields-font'>{Strings['Upload']}</span>
                                            <input type="file" accept="image/*" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleImgChange(e)} name='profileImg'></input>
                                        </span>

                                        {(this.state.profileImgPath != '' && this.state.profileImgPath != undefined && this.state.profileImgPath != null && this.state.typeOfAction == 'Edit') &&
                                            <Row className='mt-2'>
                                                <Col md={12}>
                                                    <div className='task-action-file-details-file-name  '>
                                                        <a href={this.state.profileImgPath} className='black-color line-break-file-name' target='_blank'><i className='far fa-file-image'></i> {this.state.profileImgPath}</a>
                                                    </div>
                                                </Col>

                                            </Row>
                                        }
                                        {(this.state.progressUploadHeader != undefined && this.state.progressUploadHeader > 0) &&
                                            <Progress striped color="success" value={this.state.progressUploadHeader} className='mb-1 mt-1'>{this.state.progressUploadHeader} %</Progress>
                                        }
                                        {(this.state.displayErrorHeaderMsg == true) &&
                                            <div className='invalid-feedback d-block mb-1'>{Strings['Upload Image only']}</div>
                                        }

                                    </div>
                                    {/* Profile Image */}


                                    {/* title */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['title'] } placeholder={Strings['title']}
                                        name="title"  errorMessage={Strings['This field is required']} value={this.state.title} />

                                    {/* displayAs */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Display As'] + ' *'} placeholder={Strings['Display As']}
                                        name="displayAs" required errorMessage={Strings['This field is required']} value={this.state.displayAs} />

                                    {/* countryId */}
                                    <AvField errorMessage={Strings['This field is required']}  label={Strings['Country']+ ' *'} required type="select" name="country" value={this.state.country} onChange={this.handleInputChange}
                                        Placeholder={Strings['please Select Country']}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['please Select Country']}</option>
                                        {this.state.countryList.map((data) => this.setUpSelectedOptions(data, 'countryList'))}
                                    </AvField>

                                    {/* cityId */}
                                    <AvField errorMessage={Strings['This field is required']}  label={Strings['City']+ ' *'} required type="select" name="city" value={this.state.city} onChange={this.handleInputChange}>
                                        <option style={{ 'display': 'none' }} value="" defaultValue >{Strings['Please Select City']}</option>
                                        {this.state.centerList.map((data) => this.setUpSelectedOptions(data, 'centerList'))}
                                    </AvField>

                                    {/* address */}
                                    <AvField className="" onChange={this.handleInputChange}
                                        label={Strings['Address'] + ' *'} placeholder={Strings['Address']}
                                        name="Address" required errorMessage={Strings['This field is required']} value={this.state.Address} />



                                    {/* gender */}

                                    <AvRadioGroup errorMessage={Strings['This field is required']}  inline name="gender" label={Strings['gender'] + ' *'} required value={this.state.gender}>
                                        <AvRadio className={"custom-radio"} customInput label={Strings['Male']} value="1" onChange={this.handleInputChange} />
                                        <AvRadio className={"custom-radio"} customInput label={Strings['Female']} value="2" onChange={this.handleInputChange} />
                                    </AvRadioGroup>
                                    {/* birthOfDate */}
                                    <AvField className={"text-ar-right"} max={CurruntDate} label={Strings['Date']+ ' *'} name="Date" placeholder={Strings['Date']} value={this.state.Date} onChange={this.handleInputChange} type='date'
                                        errorMessage={(this.state.Date == null && this.state.Date == '' && this.state.Date == undefined) ? Strings['This field is required'] : Strings['is not a valid ']}
                                    />

                                    {this.state.AddError != null && this.state.AddError != '' && this.state.AddError != undefined &&
                                        <div className="mt-1">
                                            <div className="d-flex">
                                                <div style={{ 'width': '5%' }}>
                                                    <ErrorOutlineIcon className='errorColor' />
                                                </div>
                                                <div className={'mt-1'}>
                                                    <span className={"errorColor"}>{this.state.AddError}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div className="text-right mb-5">
                                        {this.state.startSave == 0 ?
                                            <Button type="submit" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default" : "ml-4  btn-success submit-default"}>{Strings['Submit']}</Button>
                                            :
                                            <Button type="button" className={localStorage.getItem('LANG') == 2 ? "mr-4  btn-success submit-default bt-color" : "ml-4  btn-success submit-default bt-color"}><CircularProgress className="SaveCircularProgressColor" /> {Strings['Submit']}</Button>
                                        }
                                        <Button type="button" className=" btn-danger cancel-default" onClick={this.props.CloseDrawerFunc}>{Strings['Cancel']}</Button>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    }
                </Col>

            </div>
        );
    }
}


export default userForm;
